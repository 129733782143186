import { createTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const { palette } = createTheme();

const theme = createTheme({
  palette: {
    secondary: {
      main: "#FF0000",
    },
    primary: {
      main: "#8FE445",
      dark: "#4EA403",
    },
    custom: {
      blackShade1: {
        main: "#3E4041",
        contrastText: "#fff",
        light: "#5F6163"
      },
      greenShade1: {
        main: "#E0FFC4",
      }
    },
    error: {
      main: "#DF4C18",
    },
  },
  typography: {
    fontFamily: "Albert Sans",
    button: {
      textTransform: "none",
      fontSize: "16px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        },
      },
    },
  },
});

const getCustomColor = (colorName) => {
  return theme.palette.custom[colorName];
};

export { theme, getCustomColor };
