import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Drawer,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useViewportWidth from "../../Hooks/useViewportWidth";
import { getCustomColor } from "../../utils/theme";
import {useGetIntelLocationGroupsMutation } from "../../services/Properties";
import { toast } from "react-toastify";
import "./GISDashboardSidebar.css";
import { setLocationGroupData } from "../../slices/workPlanSlice";
import SidebarSummary from "./SidebarSummary";
import SidebarDetail from "./SidebarDetail";

const GISDashboardSidebar = ({ isPlannerDrawerOpen, togglePlannerDrawer }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const isSummary = useSelector((state) => state.gisDashboard.isSummary);
  const width = useViewportWidth();
  const user = JSON.parse(localStorage.getItem("user"));

  const [getIntelLocationGroups, { isLoading }] = useGetIntelLocationGroupsMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const locationData = await getIntelLocationGroups(
          user?.organization
        ).unwrap();
        dispatch(setLocationGroupData(locationData?.results));
        localStorage.setItem(
          "locationGroups",
          JSON.stringify(locationData?.results)
        );
      } catch (error) {
        toast.error(error?.data?.detail, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    })();
  }, []);

  return (
    <Drawer
      anchor="left"
      open={isPlannerDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: width < 768 ? "100vw" : "35vw",
          top: "8vh",
          zIndex: 1,
        },
      }}
    >
      <Box className="sidebarDrawer">
        {isSummary ? <SidebarSummary /> : <SidebarDetail />}
      </Box>
    </Drawer>
  );
};

export default GISDashboardSidebar;
