import toGeoJSON from "@mapbox/togeojson";
import { Box, Dialog, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import "./ProductSelectionModal.css";
import { setProductSelected } from "../../slices/globalSlice";
import { IoClose } from "react-icons/io5";
import { getCustomColor } from "../../utils/theme";

const ProductSelectionModal = ({ isOpen, setIsOpen }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const SelectedProduct = localStorage.getItem("SelectedProduct");

  const handleSelect = (product) => {
    setIsOpen(false);
    if (product === "crew_planner") {
      navigate("/");
      dispatch(setProductSelected("crew_planner"));
      localStorage.setItem("SelectedProduct", "crew_planner");
    } else if (product === "crew_intel") {
      navigate("/dashboard");
      dispatch(setProductSelected("crew_intel"));
      localStorage.setItem("SelectedProduct", "crew_intel");
    }
  };
  return (
    <Dialog open={isOpen} fullScreen maxWidth="lg">
      {SelectedProduct && (
        <div className="closeButton">
          <IoClose
            size={30}
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          />
        </div>
      )}
      <Box className="productSelectionContianer">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ color: blackShade1.main }}
        >
          Select Product
        </Typography>
        <Box className="productsContainer">
          <Box
            className="mainProduct"
            sx={{
              border:
                SelectedProduct === "crew_planner"
                  ? "2px solid #60b826"
                  : "2px solid #E0E0E0",
            }}
            onClick={() => handleSelect("crew_planner")}
          >
            <Typography variant="body1" sx={{ color: blackShade1.main }}>
              Crew Planner
            </Typography>
          </Box>
          <Box
            className="mainProduct"
            sx={{
              border:
                SelectedProduct === "crew_intel"
                  ? "2px solid #60b826"
                  : "2px solid #E0E0E0",
            }}
            onClick={() => handleSelect("crew_intel")}
          >
            <Typography variant="body1" sx={{ color: blackShade1.main }}>
              Crew Intel
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProductSelectionModal;
