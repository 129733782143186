import React, { useEffect } from "react";
import "./Survey.css";
import { APIProvider } from "@vis.gl/react-google-maps";
import SurveyMap from "../GoogleMapsDraw/SurveyMap";
import StepperForm from "../../components/StepperForm/StepperForm";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SiteDetailsForm from "./StepsForm/SiteDetailsForm";
import CreateParcelForm from "./CreateParcel/CreateParcelForm";
import AddParkingForm from "./AddParking/AddParkingForm";
import { useParams } from "react-router-dom";
import AddServicesAndEquipmentForm from "./AddServicesAndEquipmentForm/AddServicesAndEquipmentForm";
import {
  useLazyGetParcelQuery,
  useLazyGetSpecificOrderQuery,
} from "../../services/OpenApi";
import { stepperStatus } from "../../utils/common";
import {
  setActiveStep,
  setLocationPinCoordinate,
  setParcelGeoJSON,
} from "../../slices/stepperFormSlice";
import { toast } from "react-toastify";
import CrewAdditionForm from "./CrewAdditionForm/CrewAdditionForm";
import CrewEquipmentForm from "./CrewEquipmentForm/CrewEquipmentForm";

const Survey = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const [getSpecificOrder] = useLazyGetSpecificOrderQuery();
  const [getParcel] = useLazyGetParcelQuery();
  const {
    stepperForm: { activeStep, locationPinCoordinate },
  } = useSelector((state) => state);

  const getOrder = async (id) => {
    try {
      const response = await getSpecificOrder({
        orderId: id,
        orgName: user.organization,
      });
      if (response?.data) {
        const data = response?.data?.data;
        dispatch(
          setLocationPinCoordinate({
            lat: data?.locationCoordinates?.lat,
            lng: data?.locationCoordinates?.lon,
          })
        );
        const step  = data?.status === "EQUIPMENT" ? stepperStatus[data?.status] : stepperStatus[data?.status] + 1
        dispatch(setActiveStep(step));
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getParcelFunc = async (id) => {
    try {
      const response = await getParcel({
        orderId: id,
        orgName: user.organization,
      });
      if (response.error) {
        if (response.error.status === "FETCH_ERROR") {
          toast.error(response?.error?.error, {
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else {
          toast.error(response?.error, {
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      } else if (response?.data) {
        Object.keys(response?.data?.data).length > 0 && dispatch(setParcelGeoJSON(response?.data?.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (params?.id && user?.organization) {
      getOrder(params.id);
      getParcelFunc(params.id);
    }
  }, [user, params]);

  return (
    <>
      {locationPinCoordinate && (
        <div className="stepperContianer">
          <StepperForm />
        </div>
      )}
      <div
        className="surveyContainer"
        style={{
          height: activeStep > 0 ? "83vh" : "92vh",
          marginTop: activeStep === 0 && "8vh",
        }}
      >
        {locationPinCoordinate && (
          <>
            <div className="stepDetailsContianer">
              <Box sx={{ width: 400 }}>
                {activeStep === 1 && <SiteDetailsForm />}
                {activeStep === 2 && <CreateParcelForm />}
                {activeStep === 3 && <AddParkingForm />}
                {activeStep === 4 && <AddServicesAndEquipmentForm />}
                {activeStep === 5 && <CrewAdditionForm />}
                {activeStep === 6 && <CrewEquipmentForm />}
              </Box>
            </div>
          </>
        )}
        <APIProvider apiKey="AIzaSyDVIMgTN3Ul0B78ZnXbj_tb37j8BjzzrFY">
          <SurveyMap />
        </APIProvider>
      </div>
    </>
  );
};

export default Survey;
