import React, { useEffect, useState } from "react";
import "./Properties.css";
import ParcelTable from "./Components/ParcelTable";
import SavedPlans from "./Components/SavedPlans";
import { useNavigate } from "react-router-dom";
import { useLazyGetOrganizationDetailsQuery } from "../../services/Auth";
import {
  setDefaultUnits,
  setIsAllProductsPermission,
  setProductSelected,
} from "../../slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import ProductSelectionModal from "../../Modals/ProductSelectionModal/ProductSelectionModal";
import { toast } from "react-toastify";

const Properties = () => {
  const [isProductSelectModalOpen, setIsProductSelectModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const SelectedProduct = localStorage.getItem("SelectedProduct");
  const [getOrganizationDetails] = useLazyGetOrganizationDetailsQuery();

  const fetchAndSetOrganizationDetails = async (organization) => {
    try {
      const data = await getOrganizationDetails(organization).unwrap();
      dispatch(setDefaultUnits(data?.meta_data?.defaultUnits));

      localStorage.setItem("metaData", JSON.stringify(data?.meta_data));
      if (data?.meta_data?.application_services) {
        const { crew_intel, crew_planner } =
          data?.meta_data?.application_services;

        if (crew_intel && crew_planner) {
          dispatch(setIsAllProductsPermission(true));
          setIsProductSelectModalOpen(true);
        } else if (crew_intel) {
          navigate("/dashboard");
          dispatch(setProductSelected("crew_intel"));
        } else if (crew_planner) {
          navigate("/");
          dispatch(setProductSelected("crew_planner"));
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while fetching organization details", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      throw error;
    }
  };

  useEffect(() => {
    if (!user?.organization) {
      navigate("/newuser", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (!SelectedProduct) {
      fetchAndSetOrganizationDetails(user?.organization);
    }
  }, []);

  return (
    <div className="propertiesContainer">
      <SavedPlans />
      <ParcelTable />
      {isProductSelectModalOpen && (
        <ProductSelectionModal
          isOpen={isProductSelectModalOpen}
          setIsOpen={setIsProductSelectModalOpen}
        />
      )}
    </div>
  );
};

export default Properties;
