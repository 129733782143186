import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { useMap } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useViewportWidth from "../Hooks/useViewportWidth";

export const DeckGlOverlay = ({
  layers,
  pickable,
  typedArrayManagerProps,
  isVisible,
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useMap();
  const showLabels = useSelector((state) => state.global.showLabels);
  const [deck, setDeck] = useState(null);
  const width = useViewportWidth();
  // Check if the map has loaded
  useEffect(() => {
    if (map) {
      const listener = map.addListener("tilesloaded", () => {
        setMapLoaded(true);
      });

      return () => listener.remove();
    }
  }, [map]);

  // Initialize and clean up the overlay when the map is loaded, showLabels changes, or visibility changes
  useEffect(() => {
    if (mapLoaded && isVisible) {
      if (deck) {
        deck.setMap(null);
        deck.finalize();
      }

      const newDeck = new GoogleMapsOverlay();
      newDeck.setMap(map);
      setDeck(newDeck);

      return () => {
        if (newDeck) {
          newDeck.setMap(null);
          newDeck.finalize();
        }
      };
    }
  }, [mapLoaded, showLabels, map, isVisible]);

  // Update the overlay layers whenever the layers prop changes
  useEffect(() => {
    if (deck && mapLoaded && isVisible) {
      try {
        deck.setProps({
          layers: layers,
          interleaved: true,
          _pickable: pickable || false,
          parameters: {
            depthTest: false,
          },
          useDevicePixels: 1.5,
          _typedArrayManagerProps: { overAlloc: 1, poolSize: 0 },
        });
      } catch (error) {
        console.error("Error setting Deck.gl props:", error);
      }
    }
  }, [deck, layers, mapLoaded, isVisible]);

  // No DOM rendered by this component
  return null;
};
