import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
  IconButton,
  Box,
  tableCellClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  convertAreaUnitToStoredUnit,
  convertProductionUnitToStoredUnit,
  convertSecondsToHoursAndMinutes,
} from "../../../utils/common";
import { setSelectedGISServiceDate } from "../../../slices/gisDashboardSlice";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
    fontSize: "12px",
  },
  whiteSpace: "nowrap",
}));

const OperationTable = ({ operationTableData }) => {
  const dispatch = useDispatch();
  const metaData = localStorage.getItem("metaData");
  const areaUnit = metaData?.units_config?.area?.type;
  const productionUnit = metaData?.units_config?.production?.type;
  const selectedGISServiceDate = useSelector(
    (state) => state.gisDashboard.selectedGISServiceDate
  );
  const beaconData = useSelector((state) => state.gisDashboard.beaconsListData);
  const [openRow, setOpenRow] = useState({});

  const handleServicesCheckboxChange = (date) => {
    dispatch(setSelectedGISServiceDate(date));
  };

  const toggleRow = (date) => {
    setOpenRow((prevOpen) => ({
      ...prevOpen,
      [date]: !prevOpen[date],
    }));
  };
  const getEquipmentName = (key) => {
    const item =
      beaconData &&
      beaconData.find(
        (item) => item.core_attributes && item.core_attributes.beacon_id === key
      );
    return item ? item.core_attributes.display_name : key;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="operation table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Date and Equipment</StyledTableCell>
            <StyledTableCell align="center">Area covered</StyledTableCell>
            <StyledTableCell align="center">Area covered (%)</StyledTableCell>
            <StyledTableCell align="center">Time</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operationTableData.map((dailyData, index) => {
            const {
              date,
              location_key,
              beacons_list,
              total_duration,
              area_covered,
              distance_covered,
              completion_percentage,
            } = dailyData;
            const time = convertSecondsToHoursAndMinutes(total_duration);

            return (
              <React.Fragment key={total_duration * index}>
                <TableRow>
                  <StyledTableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => toggleRow(date)}
                    >
                      {openRow[date] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <div className="plannerNameContainer">
                      <div className="checkboxContainer">
                        <Checkbox
                          size="small"
                          checked={selectedGISServiceDate[date] || false}
                          onChange={() => handleServicesCheckboxChange(date)}
                        />
                      </div>
                      <div className="serviceNameContainer">{date}</div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {location_key?.includes("hard-edge")
                      ? convertAreaUnitToStoredUnit(
                          distance_covered,
                          "m",
                          areaUnit || "ft"
                        )
                      : convertAreaUnitToStoredUnit(
                          area_covered,
                          "sqm",
                          areaUnit || "sqft"
                        )}{" "}
                    {location_key?.includes("hard-edge")
                      ? areaUnit || "ft"
                      : areaUnit || "sqft"}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {completion_percentage.toFixed(2)} %
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {time?.hours} hr {time?.minutes} min
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    colSpan={6}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <Collapse in={openRow[date]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="beacon details">
                          <TableBody>
                            {Object.keys(beacons_list).map(
                              (beacon, beaconIndex) => {
                                const beaconData = beacons_list[beacon];
                                const durationData =
                                  convertSecondsToHoursAndMinutes(
                                    beaconData.total_duration
                                  );

                                return (
                                  <TableRow key={beaconIndex}>
                                    <TableCell component="th" scope="row">
                                      {getEquipmentName(beacon)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {location_key?.includes("hard-edge")
                                        ? convertAreaUnitToStoredUnit(
                                            beaconData?.distance,
                                            "m",
                                            areaUnit || "ft"
                                          )
                                        : convertAreaUnitToStoredUnit(
                                            beaconData?.area,
                                            "sqm",
                                            areaUnit || "sqft"
                                          )}{" "}
                                      {location_key?.includes("hard-edge")
                                        ? areaUnit || "ft"
                                        : areaUnit || "sqft"}
                                    </TableCell>
                                    <TableCell align="center">
                                      {location_key?.includes("hard-edge")
                                        ? (
                                            (convertAreaUnitToStoredUnit(
                                              beaconData?.distance,
                                              "m",
                                              areaUnit || "ft"
                                            ) /
                                              convertAreaUnitToStoredUnit(
                                                distance_covered,
                                                "m",
                                                areaUnit || "ft"
                                              )) *
                                            100
                                          ).toFixed(2)
                                        : (
                                            (convertAreaUnitToStoredUnit(
                                              beaconData?.area,
                                              "sqm",
                                              areaUnit || "sqft"
                                            ) /
                                              convertAreaUnitToStoredUnit(
                                                area_covered,
                                                "sqm",
                                                areaUnit || "sqft"
                                              )) *
                                            100
                                          ).toFixed(2)}{" "}
                                      %
                                    </TableCell>
                                    <TableCell>
                                      {location_key?.includes("hard-edge")
                                        ? convertProductionUnitToStoredUnit(
                                            beaconData?.distance * 3600 /
                                              total_duration,
                                            "mph",
                                            productionUnit || "ftph"
                                          )
                                        : convertProductionUnitToStoredUnit(
                                            beaconData?.area * 3600 / total_duration,
                                            "sqmph",
                                            productionUnit || "sqftph"
                                          )}{" "}
                                      {location_key?.includes("hard-edge")
                                        ? productionUnit || "ft/hr"
                                        : productionUnit || "sqft/hr"}
                                    </TableCell>
                                    <TableCell align="center">
                                      {durationData.hours} hr{" "}
                                      {durationData.minutes} min
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </StyledTableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperationTable;
