import { polygon as turfPolygon, booleanOverlap } from "@turf/turf";
import jwtDecode from "jwt-decode";
import _ from "lodash";

export const stepperStatus = {
  SITE: 1,
  PARCEL: 2,
  PARKING: 3,
  SERVICE: 4,
  CREW: 5,
  EQUIPMENT: 6,
};

export const checkIfLogin = () => {
  const accessToken = localStorage.getItem("accessToken");
  return !!accessToken;
};

export function formatReadableDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleString("en-US", options);
}

export const getTokenExpiry = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      // Expiry time is in seconds, convert to milliseconds
      const expiryTimeInMillis = decodedToken.exp * 1000;
      return new Date(expiryTimeInMillis);
    } else {
      // If token or expiry time is missing, return null
      return null;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

export const ShuffleJson = (file) => {
  // Separate the features based on geometry type
  let lineStringFeatures = [];
  let otherFeatures = [];

  file.features.forEach((feature) => {
    if (feature.geometry.type === "LineString") {
      lineStringFeatures.push(feature);
    } else {
      otherFeatures.push(feature);
    }
  });

  // Concatenate the features, placing LineString features at the end
  file.features = otherFeatures.concat(lineStringFeatures);
};

export function convertTimestampToFormattedDate(timestamp) {
  const date = new Date(timestamp);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export function formatTimestampForCrewIntel(timestamp) {
  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
}

export function convertTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  return `${hours}h ${mins}m`;
}

export function formatDistance(feet) {
  return (feet / 5280).toFixed(2) + "mi";
}

export function calculateTotalTime(start, end) {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const timeDifference = endTime - startTime;

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Return the total time as an object
  return `${hours} hrs ${minutes} min ${seconds} sec`;
}

export function convertStartAndEndTimes(start, end) {
  start.setHours(start.getHours() - 0.5, 0, 1, 0);
  end.setHours(start.getHours() + 9, 0, 1, 0);

  // Adjust end time to be one hour more than the original
  // end.setHours(end.getHours() + 1, end.getMinutes(), end.getSeconds(), end.getMilliseconds());
  return { start, end };
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.substring(0, num) + "...";
  } else {
    return str;
  }
}

export function getEquipmentTimeFormatted(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes are less than 10
  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
}

export function calculateTotalAssignmentsForCrewMember(data) {
  const updatedData = _.cloneDeep(data);

  for (const crewId in updatedData?.crewMemberDayWise) {
    let crewMember = updatedData.crewMemberDayWise[crewId];
    let totalAssignTravelTime = 0;
    let totalAssignTimeOfWork = 0;
    let totalAssignTravelDistance = 0;

    for (const day of crewMember.day) {
      for (const polygonId of day.polygon) {
        const polygon = updatedData.polygons[polygonId];
        if (polygon && polygon.crewMemberId === parseInt(crewId)) {
          totalAssignTravelTime += polygon.travelTime;
          totalAssignTimeOfWork += polygon.duration;
          totalAssignTravelDistance += polygon.travelDistance;
        }
      }
    }

    // Update crewMembers with the calculated values
    updatedData.crewMembers[crewId].totalAssignTravelTime =
      totalAssignTravelTime;
    updatedData.crewMembers[crewId].totalAssignTimeOfWork =
      totalAssignTimeOfWork;
    updatedData.crewMembers[crewId].totalAssignTravelDistance =
      totalAssignTravelDistance;
  }

  return updatedData;
}

export function calculateServicesTotal(data) {
  const serviceTotals = {};

  // Calculate total travel time and total area for each service
  for (const polygonId in data?.polygons) {
    const polygon = data.polygons[polygonId];
    const serviceId = polygon?.serviceId;
    const work = polygon?.duration;
    const travel = polygon?.travelTime;
    const measurement = polygon?.measurement;

    if (!serviceTotals[serviceId]) {
      serviceTotals[serviceId] = {
        workTime: 0,
        travelTime: 0,
        totalArea: 0,
      };
    }

    serviceTotals[serviceId].workTime += work;
    serviceTotals[serviceId].travelTime += travel;
    serviceTotals[serviceId].totalArea += measurement;
  }

  // Add workTime and totalArea to the corresponding service
  for (const serviceId in serviceTotals) {
    if (data.services[serviceId]) {
      data.services[serviceId].workTime = serviceTotals[serviceId].workTime;
      data.services[serviceId].travelTime = serviceTotals[serviceId].travelTime;
      data.services[serviceId].totalArea = serviceTotals[serviceId].totalArea;
    }
  }
  return data;
}

export function hexToRgb(hex) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
}

export const getMeasuringUnit = (measurementName) => {
  switch (measurementName) {
    case "HECTARE_PER_HOUR":
      return "hectare/hr";
    case "SQUARE_FEET_PER_HOUR":
      return "sqft/h";
    case "SQUARE_FEET_PER_MIN":
      return "sqft/min";
    case "FEET_PER_HOUR":
      return "ft/hr";
    case "FEET_PER_MIN":
      return "ft/min";
    case "ACRES_PER_HOUR":
      return "acre/hr";
    case "ACRES_PER_MIN":
      return "acre/min";
    case "MILES_PER_HOUR":
      return "mph";
    case "MILES_PER_MIN":
      return "mpm";
    case "KILOMETERS_PER_HOUR":
      return "kms/hr";
    case "KILOMETERS_PER_HOUR":
      return "km/hr";
    default:
      return "ft/min";
  }
};
export const getDashboardUnits = (measurementName) => {
  switch (measurementName) {
    case "Square meter":
      return "sqm";
    case "Meter":
      return "m";
    default:
      return "m";
  }
};

const unitConversionsToFeetPerMinute = {
  "sqft/h": 1 / 60,
  "sqft/min": 1,
  "ft/hr": 1 / 60,
  "ft/min": 1,
  "acre/hr": 43560 / 60,
  "acre/min": 43560,
  "hectare/hr": 107639 / 60,
  mph: 5280 / 60,
  mpm: 5280,
  "kms/hr": 3280.84 / 60,
  "km/hr": 3280.84 / 60,
};

export const convertToFeetPerMinute = (value, unit) => {
  const conversionFactor =
    unitConversionsToFeetPerMinute[getMeasuringUnit(unit)];
  if (conversionFactor === undefined) {
    throw new Error(`Unsupported unit: ${unit}`);
  }
  return value * conversionFactor;
};

const conversionFactorsFromFeetPerMinute = {
  "sqft/h": 60,
  "sqft/min": 1,
  "ft/hr": 60,
  "ft/min": 1,
  "acre/hr": 60 / 43560,
  "acre/min": 1 / 43560,
  "hectare/hr": 60 / 107639,
  mph: 60 / 5280,
  mpm: 1 / 5280,
  "kms/hr": 60 / 3280.84,
  "km/hr": 60 / 3280.84,
};

export const convertFromFeetPerMinute = (value, unit) => {
  const conversionFactor =
    conversionFactorsFromFeetPerMinute[getMeasuringUnit(unit)];
  if (conversionFactor === undefined) {
    throw new Error(`Unsupported unit: ${unit}`);
  }
  return value * conversionFactor;
};

export function convertProductionUnitRate(value, fromUnit, toUnit) {
  const conversionFactors = {
    // Area rates per hour
    HECTARE_PER_HOUR: {
      HECTARE_PER_HOUR: 1,
      SQUARE_FEET_PER_HOUR: 107639.104167,
      ACRES_PER_HOUR: 2.4710538147,
      SQUARE_FEET_PER_MIN: 107639.104167 / 60,
      ACRES_PER_MIN: 2.4710538147 / 60,
      FEET_PER_HOUR: 107639.104167,
      FEET_PER_MIN: 107639.104167 / 60,
    },
    SQUARE_FEET_PER_HOUR: {
      HECTARE_PER_HOUR: 1 / 107639.104167,
      SQUARE_FEET_PER_HOUR: 1,
      ACRES_PER_HOUR: 1 / 43560,
      SQUARE_FEET_PER_MIN: 1 / 60,
      ACRES_PER_MIN: 1 / 43560 / 60,
      FEET_PER_HOUR: 1,
      FEET_PER_MIN: 1 / 60,
    },
    ACRES_PER_HOUR: {
      HECTARE_PER_HOUR: 1 / 2.4710538147,
      SQUARE_FEET_PER_HOUR: 43560,
      ACRES_PER_HOUR: 1,
      SQUARE_FEET_PER_MIN: 43560 / 60,
      ACRES_PER_MIN: 1 / 60,
      FEET_PER_HOUR: 43560,
      FEET_PER_MIN: 43560 / 60,
    },

    // Area rates per minute
    SQUARE_FEET_PER_MIN: {
      HECTARE_PER_HOUR: 60 / 107639.104167,
      SQUARE_FEET_PER_HOUR: 60,
      ACRES_PER_HOUR: 60 / 43560,
      SQUARE_FEET_PER_MIN: 1,
      ACRES_PER_MIN: 1 / 43560,
      FEET_PER_HOUR: 60,
      FEET_PER_MIN: 1,
    },
    ACRES_PER_MIN: {
      HECTARE_PER_HOUR: 60 / 2.4710538147,
      SQUARE_FEET_PER_HOUR: 43560 * 60,
      ACRES_PER_HOUR: 60,
      SQUARE_FEET_PER_MIN: 43560,
      ACRES_PER_MIN: 1,
      FEET_PER_HOUR: 43560 * 60,
      FEET_PER_MIN: 43560,
    },

    // Linear rates per hour and minute (assuming these are meant to be area rates)
    FEET_PER_HOUR: {
      HECTARE_PER_HOUR: 1 / 107639.104167,
      SQUARE_FEET_PER_HOUR: 1,
      ACRES_PER_HOUR: 1 / 43560,
      SQUARE_FEET_PER_MIN: 1 / 60,
      ACRES_PER_MIN: 1 / 43560 / 60,
      FEET_PER_HOUR: 1,
      FEET_PER_MIN: 1 / 60,
    },
    FEET_PER_MIN: {
      HECTARE_PER_HOUR: 60 / 107639.104167,
      SQUARE_FEET_PER_HOUR: 60,
      ACRES_PER_HOUR: 60 / 43560,
      SQUARE_FEET_PER_MIN: 1,
      ACRES_PER_MIN: 1 / 43560,
      FEET_PER_HOUR: 60,
      FEET_PER_MIN: 1,
    },
  };

  // Check if the provided units are valid
  if (!conversionFactors[fromUnit] || !conversionFactors[toUnit]) {
    throw new Error("Invalid units provided.");
  }

  // Convert the value to the target unit
  const result = value * conversionFactors[fromUnit][toUnit];
  return result;
}
export function convertTransitionPace(value, fromUnit, toUnit) {
  const conversionFactors = {
    // Area rates per hour
    MILES_PER_HOUR: {
      MILES_PER_HOUR: 1,
      KILOMETERS_PER_HOUR: 1.60934,
    },
    KILOMETERS_PER_HOUR: {
      MILES_PER_HOUR: 1 / 1.60934,
      KILOMETERS_PER_HOUR: 1,
    },
  };

  // Check if the provided units are valid
  if (!conversionFactors[fromUnit] || !conversionFactors[toUnit]) {
    throw new Error("Invalid units provided.");
  }

  // Convert the value to the target unit
  const result = value * conversionFactors[fromUnit][toUnit];
  return result;
}

export function getServiceColor(serviceName) {
  switch (serviceName) {
    case "Turf":
      return "#c7e9c0";
    case "Soft Edge":
      return "#d69253";
    case "String Trimmer":
      return "#d69253";
    case "String Trimming":
      return "#d69253";
    case "Hard Edge":
      return "#915c30";
    case "Mulch Beds":
      return "#e8d966";
    case "Rock Beds":
      return "#575c63";
    case "Hedge":
      return "#197901";
    case "Cleanup Blowing":
      return "#72b4da";
    case "Flower Beds":
      return "#b813ce";
    case "obstacle":
      return "#141414";
    case "Water Body":
      return "#46d9fd";
    default:
      return "black";
  }
}
export function getServiceColorRgbforGISDashboard(serviceName) {
  switch (serviceName) {
    case "turf":
      return [199, 233, 192];
    case "largeturf":
      return [108, 181, 93];
    case "smallturf":
      return [199, 233, 192];
    case "softedge":
      return [214, 146, 83];
    case "hardedge":
      return [145, 92, 48];
    case "mulchbeds":
      return [232, 217, 102];
    case "rockbeds":
      return [87, 92, 99];
    case "hedge":
      return [25, 121, 1];
    case "cleanupblowing":
      return [114, 180, 218];
    case "stringtrimmer":
      return [214, 146, 83];
    case "flowerbeds":
      return [184, 19, 206];
    case "obstacle":
      return [20, 20, 20];
    case "waterbody":
      return [70, 217, 253];
    default:
      return [0, 0, 0];
  }
}
export function getServiceColorRgb(serviceName) {
  switch (serviceName) {
    case "Turf":
      return [199, 233, 192];
    case "Large Turf":
      return [108, 181, 93];
    case "Small Turf":
      return [199, 233, 192];
    case "Soft Edge":
      return [214, 146, 83];
    case "Hard Edge":
      return [145, 92, 48];
    case "Mulch Beds":
      return [232, 217, 102];
    case "Rock Beds":
      return [87, 92, 99];
    case "Hedge":
      return [25, 121, 1];
    case "Cleanup Blowing":
      return [114, 180, 218];
    case "String Trimmer":
      return [214, 146, 83];
    case "String Trimming":
      return [214, 146, 83];
    case "Flower Beds":
      return [184, 19, 206];
    case "obstacle":
      return [20, 20, 20];
    case "Water Body":
      return [70, 217, 253];
    default:
      return [0, 0, 0];
  }
}

export const serviceColors = {
  rockBeds: "#575c63",
  mulchBeds: "#e8d966",
  turf: "#c7e9c0",
  smallTurf: "#6cb55d",
  largeTurf: "#c7e9c0",
  hedge: "#197901",
  cleanupBlowing: "#72b4da",
  stringTrimmer: "#d69253",
  lineSmallTurf: "#a1d99b",
  lineLargeTurf: "#c9e7c3",
  lineMulchBeds: "#beac2d",
  lineHedge: "#0b7000",
  lineRockBeds: "#5c5c5c",
  lineHardEdge: "#915c30",
  lineSoftEdge: "#d69253",
  lineStringTrimmer: "#d69253",
  lineObstacle: "#141414",
};

export const crewMemberColors = [
  "#aa0b0b",
  "#0c4f8c",
  "#eb0979",
  "#dbc443",
  "#ff9f09",
  "#9471ef",
  "#1a671a",
  "#718290",
  "#04f5d9",
  "#97a20f",
];

export const getCrewMemberColor = (key, data) => {
  return data[key]?.crewMemberColor || null;
};

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function assignCrewMemberColors(data) {
  const crewMemberIds = Object.keys(data.crewMemberDayWise);

  crewMemberIds.forEach((memberId, index) => {
    // Calculate the color index using modulo operation to loop through colors
    const colorIndex = index % crewMemberColors.length;
    data.crewMemberDayWise[memberId].crewMemberColor =
      crewMemberColors[colorIndex];
  });

  return data;
}

export function assignCrewMemberColorsToAnimation(data) {
  const updatedData = _.cloneDeep(data);
  Object.keys(updatedData).forEach((memberId) => {
    const hash = hashCode(memberId);
    const colorIndex = hash % crewMemberColors.length;
    updatedData[memberId].crewMemberColor = crewMemberColors[colorIndex];
  });
  return updatedData;
}

export function extractFolderId(url) {
  const pattern = /\/folders\/([a-zA-Z0-9_-]+)/;
  const match = url?.match(pattern);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // or an appropriate error/indicator
  }
}

export const getCrewMemberName = (crewMemberId, lookUp) => {
  const crewData = lookUp?.crewMembers;
  if (crewData[crewMemberId]) {
    return crewData[crewMemberId].name;
  } else {
    return "Crew member not found";
  }
};

export const getServiceName = (serviceId, lookUp) => {
  const servicesData = lookUp?.services;
  if (servicesData[serviceId]) {
    return servicesData[serviceId].serviceName;
  } else {
    return "Service not found";
  }
};
export const getEquipmentName = (crewEquipmentId, lookUp) => {
  const equipments = lookUp?.equipments;
  if (equipments[crewEquipmentId]) {
    return equipments[crewEquipmentId].type;
  } else {
    return "Equipment not found";
  }
};

export const getSkillName = (skillId, skillsArray) => {
  const skill = skillsArray.filter((skill) => {
    if (skill.skillId === skillId) {
      return skill;
    }
  });
  return skill[0]?.skillName;
};

export function getEquipmentId(equipmentId, equipmentsArray) {
  const equipment = equipmentsArray.filter((equipment) => {
    if (equipment.equipmentId === equipmentId) {
      return equipment;
    }
  });
  return equipment[0]?.equipment;
}
export function getServiceNameForCrewCreation(serviceId, ServicesArray) {
  const service = ServicesArray.filter((service) => {
    if (service.id === serviceId) {
      return service;
    }
  });
  return service[0]?.description;
}

export function getFirstLetter(str) {
  const words = str.split("_");

  let FirstLetters = "";

  for (let i = 0; i < words.length; i++) {
    FirstLetters += words[i][0];
  }

  return FirstLetters;
}

function forPreviousConnectingLine(
  prevPolygon,
  lookUpData,
  hoveredPolygon,
  parkingId
) {
  const routePlan = [];
  const wayPointsGeoJson = [];

  if (hoveredPolygon?.fromParking) {
    if (hoveredPolygon?.wayPoints && hoveredPolygon?.wayPoints?.length > 0) {
      hoveredPolygon?.wayPoints.map((wayPoint, index) => {
        // Using map here as each waypoint produces one feature
        wayPointsGeoJson.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
          },
          properties: {},
        });
      });
      routePlan.push({
        entryPoint: lookUpData?.parkings[parkingId]?.point,
        exitPoint: hoveredPolygon?.wayPoints[0],
      });
      for (let i = 0; i < hoveredPolygon?.wayPoints?.length - 2; i++) {
        const wayPoint = hoveredPolygon?.wayPoints[i];
        const nextWayPoint = hoveredPolygon?.wayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          hoveredPolygon.wayPoints[hoveredPolygon?.wayPoints.length - 1],
        exitPoint: hoveredPolygon.entryPoint,
      });
    } else {
      routePlan.push({
        entryPoint: lookUpData?.parkings[parkingId]?.point,
        exitPoint: hoveredPolygon.entryPoint,
      });
    }
  }

  if (prevPolygon?.toParking) {
    // if (prevPolygon?.wayPoints && prevPolygon?.wayPoints?.length > 0) {
    //   routePlan.push({
    //     entryPoint: prevPolygon?.exitPoint,
    //     exitPoint: prevPolygon?.wayPoints[0],
    //   });
    //   for (let i = 0; i <= prevPolygon?.wayPoints.length - 2; i++) {
    //     const wayPoint = prevPolygon?.wayPoints[i];
    //     const nextWayPoint = prevPolygon?.wayPoints[i + 1];
    //     routePlan.push({
    //       entryPoint: wayPoint,
    //       exitPoint: nextWayPoint,
    //     });
    //   }
    //   routePlan.push({
    //     entryPoint:
    //     prevPolygon?.wayPoints[prevPolygon?.wayPoints?.length - 1],
    //     exitPoint: prevPolygon?.entryPoint,
    //   });
    // }
    if (
      prevPolygon?.toParkingWayPoints &&
      prevPolygon?.toParkingWayPoints?.length > 0
    ) {
      prevPolygon?.toParkingWayPoints.map((wayPoint, index) => {
        // Using map here as each waypoint produces one feature
        wayPointsGeoJson.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
          },
          properties: {},
        });
      });
      routePlan.push({
        entryPoint: prevPolygon?.exitPoint,
        exitPoint: prevPolygon?.toParkingWayPoints[0],
      });
      for (let i = 0; i <= prevPolygon?.toParkingWayPoints.length - 2; i++) {
        const wayPoint = prevPolygon?.toParkingWayPoints[i];
        const nextWayPoint = prevPolygon?.toParkingWayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          prevPolygon?.toParkingWayPoints[
            prevPolygon?.toParkingWayPoints?.length - 2
          ],
        exitPoint:
          prevPolygon?.toParkingWayPoints[
            prevPolygon?.toParkingWayPoints?.length - 1
          ],
      });
    } else {
      routePlan.push({
        entryPoint: prevPolygon?.exitPoint,
        exitPoint: lookUpData?.parkings[parkingId]?.point,
      });
    }
  } else if (
    hoveredPolygon?.wayPoints &&
    hoveredPolygon?.wayPoints?.length > 0
  ) {
    for (let i = 0; i < hoveredPolygon.wayPoints.length - 1; i++) {
      const wayPoint = hoveredPolygon.wayPoints[i];
      const nextWayPoint = hoveredPolygon.wayPoints[i + 1];
      if (wayPoint && hoveredPolygon) {
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
    }
    hoveredPolygon?.wayPoints.map((wayPoint, index) => {
      // Using map here as each waypoint produces one feature
      wayPointsGeoJson.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
        },
        properties: {},
      });
    });
  } else {
    if (hoveredPolygon && prevPolygon) {
      routePlan.push({
        entryPoint: prevPolygon?.exitPoint,
        exitPoint: hoveredPolygon?.entryPoint,
      });
    }
  }
  return { routePlan: routePlan, wayPointsGeoJson: wayPointsGeoJson };
}

function forCurrentHoveredPolygon(
  hoveredPolygon,
  lookUpData,
  nextPolygon,
  parkingId
) {
  const routePlan = [];
  const wayPointsGeoJson = [];

  if (hoveredPolygon?.toParking) {
    if (
      hoveredPolygon?.toParkingWayPoints &&
      hoveredPolygon?.toParkingWayPoints?.length > 0
    ) {
      hoveredPolygon?.toParkingWayPoints.map((wayPoint, index) => {
        // Using map here as each waypoint produces one feature
        wayPointsGeoJson.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
          },
          properties: {},
        });
      });
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: hoveredPolygon?.toParkingWayPoints[0],
      });
      for (let i = 0; i <= hoveredPolygon?.toParkingWayPoints.length - 2; i++) {
        const wayPoint = hoveredPolygon?.toParkingWayPoints[i];
        const nextWayPoint = hoveredPolygon?.toParkingWayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          hoveredPolygon?.toParkingWayPoints[
            hoveredPolygon?.toParkingWayPoints?.length - 2
          ],
        exitPoint:
          hoveredPolygon?.toParkingWayPoints[
            hoveredPolygon?.toParkingWayPoints?.length - 1
          ],
      });
    } else {
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: lookUpData?.parkings[parkingId]?.point,
      });
    }
  } else if (nextPolygon?.wayPoints && nextPolygon?.wayPoints?.length > 0) {
    for (let i = 0; i < nextPolygon.wayPoints.length - 1; i++) {
      const wayPoint = nextPolygon.wayPoints[i];
      const nextWayPoint = nextPolygon.wayPoints[i + 1];
      if (wayPoint && nextWayPoint) {
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
    }
    nextPolygon?.wayPoints.map((wayPoint, index) => {
      // Using map here as each waypoint produces one feature
      wayPointsGeoJson.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
        },
        properties: {},
      });
    });
  } else {
    if (hoveredPolygon && nextPolygon) {
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: nextPolygon?.entryPoint,
      });
    }
  }
  return { routePlan: routePlan, wayPointsGeoJson: wayPointsGeoJson };
}

export function getPreviousAndNextLineData(hoveredId, parkingId, lookUpData) {
  const hoveredPolygon = lookUpData?.polygons[hoveredId];
  const crewMember =
    lookUpData?.crewMemberDayWise[hoveredPolygon?.crewMemberId];
  let prevPolygonId = null;
  let nextPolygonId = null;
  crewMember?.day?.map((day) => {
    for (let i = 0; i < day?.polygon.length; i++) {
      if (day.polygon[i] === hoveredId) {
        if (i === 0) {
          nextPolygonId = day.polygon[i + 1];
          // prevPolygonId = day.polygon[i];
        } else if (i === day.polygon.length - 1) {
          prevPolygonId = day.polygon[i - 1];
        } else {
          prevPolygonId = day.polygon[i - 1];
          nextPolygonId = day.polygon[i + 1];
        }
      }
    }
  });
  const prevPolygon = lookUpData?.polygons[prevPolygonId];
  const nextPolygon = lookUpData?.polygons[nextPolygonId];
  // const prevConnectingRoute = forCurrentHoveredPolygon(
  //   prevPolygon,
  //   lookUpData,
  //   hoveredPolygon,
  //   parkingId
  // );
  const prevConnectingRoute = forPreviousConnectingLine(
    prevPolygon,
    lookUpData,
    hoveredPolygon,
    parkingId
  );
  const nextConnectingRoute = forCurrentHoveredPolygon(
    hoveredPolygon,
    lookUpData,
    nextPolygon,
    parkingId
  );

  return {
    wayPointsGeoJson: [
      ...nextConnectingRoute.wayPointsGeoJson,
      ...prevConnectingRoute.wayPointsGeoJson,
    ],
    route: {
      nextConnectingRoute: [...nextConnectingRoute.routePlan],
      prevConnectingRoute: [...prevConnectingRoute.routePlan],
    },
  };
}

export const shapeToGeoJSON = (shape) => {
  const { google } = window;
  let geojson = null;

  if (shape instanceof google.maps.Marker) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [shape.getPosition().lng(), shape.getPosition().lat()],
      },
      properties: {},
    };
  } else if (shape instanceof google.maps.Circle) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [shape.getCenter().lng(), shape.getCenter().lat()],
      },
      properties: {
        radius: shape.getRadius(),
      },
    };
  } else if (shape instanceof google.maps.Polygon) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: shape
          .getPaths()
          .getArray()
          .map((path) =>
            path.getArray().map((latlng) => [latlng.lng(), latlng.lat()])
          ),
      },
      properties: {
        area: calculatePolygonArea(shape),
      },
    };
  } else if (shape instanceof google.maps.Polyline) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: shape
          .getPath()
          .getArray()
          .map((latlng) => [latlng.lng(), latlng.lat()]),
      },
      properties: {},
    };
  } else if (shape instanceof google.maps.Rectangle) {
    const bounds = shape.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    geojson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [sw.lng(), sw.lat()],
            [ne.lng(), sw.lat()],
            [ne.lng(), ne.lat()],
            [sw.lng(), ne.lat()],
            [sw.lng(), sw.lat()],
          ],
        ],
      },
      properties: {},
    };
  }

  return geojson;
};

export const calculatePolygonArea = (polygon) => {
  const path = polygon.getPath();
  return window?.google?.maps?.geometry?.spherical?.computeArea(path);
};

export const geoJSONToShape = (geojson, map, addUpdateListener, readOnly) => {
  const { google } = window;
  const { type, geometry, properties } = geojson;
  let shape = null;
  const options = {
    map,
    editable: !readOnly,
    draggable: !readOnly,
    clickable: !readOnly,
  };

  switch (geometry.type) {
    case "Point":
      shape = new google.maps.Marker({
        position: new google.maps.LatLng(
          geometry.coordinates[1],
          geometry.coordinates[0]
        ),
        ...options,
      });
      break;
    case "LineString":
      shape = new google.maps.Polyline({
        path: geometry.coordinates.map(
          (coord) => new google.maps.LatLng(coord[1], coord[0])
        ),
        ...options,
      });
      break;
    case "Polygon":
      shape = new google.maps.Polygon({
        paths: geometry.coordinates.map((ring) =>
          ring.map((coord) => new google.maps.LatLng(coord[1], coord[0]))
        ),
        ...options,
      });
      !readOnly &&
        ["mouseup"].forEach((eventName) => addUpdateListener(eventName, shape));
      break;
    default:
      break;
  }

  return { type: geometry.type, geometry: shape };
};

export function capitalizeFirstLetter(string) {
  if (!string) return string; // handle empty string case
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Haversine formula to calculate distance between two points
function haversine(lat1, lon1, lat2, lon2) {
  function toRadians(degree) {
    return (degree * Math.PI) / 180;
  }

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

// Function to find the shortest distance combination
export function findShortestDistance(data) {
  const results = [];
  data.forEach((service) => {
    const shortestLoop = service.shortestLoop;
    const areas = service.areasToAddress;

    for (let i = 0; i < shortestLoop.length - 1; i++) {
      const currentPathId = shortestLoop[i];
      const nextPathId = shortestLoop[i + 1];

      const currentArea = areas.find((area) => area.pathId === currentPathId);
      const nextArea = areas.find((area) => area.pathId === nextPathId);

      if (!currentArea || !nextArea) continue;

      const currentGatewayPoints = currentArea.gatewayPoints;
      const nextGatewayPoints = nextArea.gatewayPoints;

      let minDistance = Infinity;
      let minPoints = {};

      currentGatewayPoints?.forEach((currPoint) => {
        nextGatewayPoints?.forEach((nextPoint) => {
          const distance = haversine(
            parseFloat(currPoint.lat),
            parseFloat(currPoint.lon),
            parseFloat(nextPoint.lat),
            parseFloat(nextPoint.lon)
          );

          if (distance < minDistance) {
            minDistance = distance;
            minPoints = { currPoint, nextPoint };
          }
        });
      });

      results.push({ ...minPoints, service: service.serviceType.description });
    }
  });

  return results;
}

export function getImagePath(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "/images/turf.avif";
    case "Turf":
      return "/images/turf.avif";
    case "Soft Edge":
      return "/images/softEdges.jpg";
    case "Soft Edging":
      return "/images/softEdges.jpg";
    case "String Trimmer":
      return "/images/softEdges.jpg";
    case "Hard Edge":
      return "/images/hardEdges.jpg";
    case "Hard Edging":
      return "/images/hardEdges.jpg";
    case "Mulching":
      return "/images/mulching.avif";
    case "Mulch Beds":
      return "/images/mulching.avif";
    case "Hedging":
      return "/images/hedging.jpg";
    case "Rock Beds":
      return "/images/rockBeds.jpg";
    case "Cleanup Blowing":
      return "/images/blower.jpeg";
    default:
      return "/Images/turf.jpg";
  }
}
export function getName(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "Turf Count :"; // Replace with your image paths
    case "Turf":
      return "Turf Count :"; // Replace with your image paths
    case "Soft Edge":
      return "Soft Edges :";
    case "Soft Edging":
      return "Soft Edges :";
    case "String Trimmer":
      return "String Trimmer :";
    case "Hard Edge":
      return "Hard Edges :";
    case "Hard Edging":
      return "Hard Edges :";
    case "Mulch Beds":
      return "Mulch Beds :";
    case "Mulching":
      return "Mulch Beds :";
    case "Rock Beds":
      return "Rock Beds :";
    default:
      return "Polygon Count :"; // A default image if no match is found
  }
}
export function getLengthOrArea(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "Area";
    case "Turf":
      return "Area";
    case "Soft Edge":
      return "Length";
    case "Soft Edging":
      return "Length";
    case "String Trimming":
      return "Length";
    case "String Trimmer":
      return "Length";
    case "Hard Edge":
      return "Length";
    case "Hard Edging":
      return "Length";
    case "Mulching":
      return "Area";
    case "Mulch Beds":
      return "Area";
    case "Rock Beds":
      return "Area";
    default:
      return "Area"; // A default image if no match is found
  }
}
export function getLengthUnit(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "ft²";
    case "Turf":
      return "ft²";
    case "Soft Edge":
      return "ft";
    case "Soft Edging":
      return "ft";
    case "String Trimmer":
      return "ft";
    case "String Trimming":
      return "ft";
    case "Hard Edge":
      return "ft";
    case "Hard Edging":
      return "ft";
    case "Mulching":
      return "ft²";
    case "Mulch Beds":
      return "ft²";
    case "Rock Beds":
      return "ft²";
    default:
      return "ft²"; // A default image if no match is found
  }
}
export function getAcres(serviceName, value) {
  switch (serviceName) {
    case "Lawn Mowing":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Turf":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Soft Edge":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Soft Edging":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "String Trimmer":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "String Trimming":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Hard Edge":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Hard Edging":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Mulching":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Mulch Beds":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Rock Beds":
      return ` ${(value / 43560).toFixed(1)} acres `;
    default:
      return ""; // A default image if no match is found
  }
}

export const setSkills = (level) => {
  switch (level) {
    case 1:
      return "Novice";
    case 2:
      return "Novice";
    case 3:
      return "Intermediate";
    case 4:
      return "Intermediate";
    case 5:
      return "Expert";
    default:
      return "Intermediate";
  }
};

export function convertSecondsToHoursAndMinutes(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return {
    hours: hours,
    minutes: minutes,
  };
}

export function getWeekRange(dateString) {
  const date = new Date(dateString);

  // Calculate the start and end dates of the week
  const startDate = new Date(date);
  const endDate = new Date(date);

  // Set the start date to the previous Sunday
  startDate.setDate(date.getDate() - date.getDay());

  // Set the end date to the next Saturday
  endDate.setDate(date.getDate() + (6 - date.getDay()));

  // Format the dates to "D MMM" (e.g., "28 Jul")
  const options = { day: "numeric", month: "short" };
  const startDateFormatted = startDate.toLocaleDateString("en-US", options);
  const endDateFormatted = endDate.toLocaleDateString("en-US", options);

  // Return the formatted date range
  return `${startDateFormatted} - ${endDateFormatted}`;
}

export function getWeekOfYear(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();

  // Start of the year
  const startOfYear = new Date(Date.UTC(year, 0, 1));
  // Find the first Monday of the year
  const firstMonday =
    startOfYear.getUTCDay() === 0
      ? new Date(startOfYear.setDate(startOfYear.getUTCDate() + 1))
      : startOfYear;

  // Get the difference in milliseconds between the date and the first Monday
  const diff = date - firstMonday;

  // Convert difference to days and calculate the week number
  const oneDay = 24 * 60 * 60 * 1000;
  const weekNumber = Math.ceil(diff / oneDay / 7);

  return `${year}WEEK${weekNumber}`;
}

export const getServiceColorFromLocationKey = (location) => {
  if (location.includes("hard-edge")) {
    return "Hard Edge";
  } else {
    return "Large Turf";
  }
};

export const getCheckboxColorBasedOnLocationKey = (location) => {
  if (location.includes("hard-edge")) {
    return "#915c30";
  } else {
    return "#6cb55d";
  }
};
export const getTimelineColorBasedOnLocationKey = (location) => {
  if (location.includes("hard-edge")) {
    return "Hard_Edging";
  } else {
    return "Small_Turf";
  }
};
export const getBackgroundColorBasedOnLocationKey = (location) => {
  if (location.includes("hard-edge")) {
    return "rgba(145, 92, 48, .5)";
  } else {
    return "rgba(199, 233, 192, .7)";
  }
};

export function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  const screenWidth = window.innerWidth;

  return (
    /mobi|mobile|android|iphone|ipad|ipod/i.test(userAgent) ||
    screenWidth <= 800
  );
}

export function addRandomOffsettoNumberCircles(coordinates) {
  const usedCoordinates = {};
  const coordinateKey = coordinates.join(",");
  let newCoordinates = [...coordinates];

  // Check if coordinates already exist
  if (usedCoordinates[coordinateKey]) {
    // Increment latitude slightly
    newCoordinates[1] += 0.000004; // Adjust as needed
  } else {
    usedCoordinates[coordinateKey] = true;
  }

  return newCoordinates;
}

export function convertAreaUnitToStoredUnit(value, currentUnit, toUnit) {
  const conversionRates = {
    sqm: 10.7639,
    sqft: 1,
    m: 3.28084,
    ft: 1,
  };

  if (conversionRates[currentUnit]) {
    const conversionFactor =
      conversionRates[currentUnit] / conversionRates[toUnit];
    const convertedValue = value * conversionFactor;
    return convertedValue?.toFixed(2);
  } else {
    return value;
  }
}
export function convertProductionUnitToStoredUnit(value, currentUnit, toUnit) {

  const conversionRates = {
    sqmph: 1,
    mph: 1,
    sqftph: 0.092903,
    ftph: 0.3048,
  };

  if (conversionRates[currentUnit]) {
    const conversionFactor =
      conversionRates[currentUnit] / conversionRates[toUnit];
    const convertedValue = value * conversionFactor;
    return convertedValue?.toFixed(2);
  } else {
    return value;
  }
}

export const unitReadableFormat = {
  sqmph: "sqm/hr",
  mph: "m/hr",
  sqftph: "sqft/hr",
  ftph: "ft/hr",
};
