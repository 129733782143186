import {
  Box,
  Checkbox,
  Divider,
  Typography,
  checkboxClasses,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Collapse,
} from "@mui/material";
import React, { useState } from "react";
import { getCustomColor } from "../../utils/theme";
import {
  convertAreaUnitToStoredUnit,
  convertProductionUnitToStoredUnit,
  convertSecondsToHoursAndMinutes,
  getBackgroundColorBasedOnLocationKey,
  getCheckboxColorBasedOnLocationKey,
  unitReadableFormat,
} from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSummary,
  setSelectedGISService,
  setSelectedGISServicePathOfMotion,
  setSelectedServiceForDetails,
} from "../../slices/gisDashboardSlice";
import {
  RiAddBoxFill,
  RiArrowRightUpFill,
  RiSubtractFill,
} from "react-icons/ri";
import { StyledTableCell } from "./Layers/OperationTable";

const SummaryCard = ({ summary }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const dispatch = useDispatch();
  const metaData = localStorage.getItem("metaData");
  const areaUnit = metaData?.units_config?.area?.type;
  const productionUnit = metaData?.units_config?.production?.type;
  const selectedGISService = useSelector(
    (state) => state.gisDashboard.selectedGISService
  );
  const beaconData = useSelector((state) => state.gisDashboard.beaconsListData);

  const [showBreakdown, setShowBreakdown] = useState(false); // State to manage table visibility

  const time = convertSecondsToHoursAndMinutes(summary?.total_duration);

  const handleServicesCheckboxChange = (serviceType) => {
    dispatch(setSelectedGISService(serviceType));
  };
  const handleServicesPathOfMotionCheckboxChange = (serviceType) => {
    dispatch(setSelectedGISServicePathOfMotion(serviceType));
  };

  const handleDetailedClick = (summary) => {
    dispatch(setIsSummary(false));
    dispatch(setSelectedServiceForDetails(summary));
  };

  const handleBreakdownClick = () => {
    setShowBreakdown((prev) => !prev);
  };
  
  const getEquipmentName = (key) => {
    const item = beaconData && beaconData.find(item => item.core_attributes && item.core_attributes.beacon_id  === key);
    return item ? item.core_attributes.display_name : key;
  };

  return (
    <Box className="summaryCardBox">
      <Box
        className="summaryCardHeaderContainer"
        sx={{
          background: getBackgroundColorBasedOnLocationKey(
            summary?.location_key
          ),
        }}
      >
        <div className="summaryCheckboxContainer">
          <Checkbox
            size="small"
            sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: getCheckboxColorBasedOnLocationKey(
                  summary?.location_key
                ),
              },
            }}
            checked={
              selectedGISService[summary?.location_key]
                ? selectedGISService[summary?.location_key?.replace("_", " ")]
                : false
            }
            onChange={() => handleServicesCheckboxChange(summary?.location_key)}
          />
          <Typography
            component="h3"
            fontWeight="600"
            sx={{
              color: getCheckboxColorBasedOnLocationKey(summary?.location_key),
            }}
            variant="body1"
          >
            {summary?.location_key}
          </Typography>
        </div>
      </Box>
      <Divider />
      <div className="summaryCardBodyContainer">
        <Typography
          component="h3"
          fontWeight="600"
          sx={{ color: blackShade1.main, marginTop: "10px" }}
          variant="body2"
        >
          {summary?.completion_percentage.toFixed(1)}% Area covered with{" "}
          {Object.keys(summary?.devices_list)?.length} Members and{" "}
          {Object.keys(summary?.beacons_list)?.length} Equipments
        </Typography>
        <div className="summaryCalculationContainer">
          <div className="summaryCalculationContainerLeft">
            <div>
              <Typography
                component="span"
                fontWeight="600"
                sx={{ color: "primary.dark" }}
                variant="body1"
              >
                {summary?.location_key?.includes("hard-edge")
                  ? convertAreaUnitToStoredUnit(
                      summary?.cumulative_distance_covered,
                      "m",
                      areaUnit || "ft"
                    )
                  : convertAreaUnitToStoredUnit(
                      summary?.cumulative_area_covered,
                      "sqm",
                      areaUnit || "sqft"
                    )}{" "}
              </Typography>{" "}
              <Typography
                component="span"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                {summary?.location_key?.includes("hard-edge")
                  ? areaUnit || "ft"
                  : areaUnit || "sqft"}{" "}
                out of
              </Typography>{" "}
              <Typography
                component="span"
                fontWeight="600"
                sx={{ color: "primary.dark" }}
                variant="body1"
              >
                {summary?.location_key?.includes("hard-edge")
                  ? convertAreaUnitToStoredUnit(
                      summary?.location_distance,
                      "m",
                      areaUnit || "ft"
                    )
                  : convertAreaUnitToStoredUnit(
                      summary?.location_area,
                      "sqm",
                      areaUnit || "sqft"
                    )}{" "}
              </Typography>
              <Typography
                component="span"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                {summary?.location_key?.includes("hard-edge")
                  ? areaUnit || "ft"
                  : areaUnit || "sqft"}
              </Typography>
            </div>
            <div>
              <Typography
                component="span"
                fontSize="10px"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                Area covered
              </Typography>
            </div>
          </div>
          <div className="summaryCalculationContainerRight">
            <div>
              <Typography
                component="span"
                fontWeight="600"
                sx={{ color: "primary.dark" }}
                variant="body1"
              >
                {time?.hours}
              </Typography>{" "}
              {summary?.hours > 0 && (
                <Typography
                  component="span"
                  fontSize="12px"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  hr
                </Typography>
              )}
              <Typography
                component="span"
                fontWeight="600"
                sx={{ color: "primary.dark" }}
                variant="body1"
              >
                {time?.minutes}
              </Typography>{" "}
              <Typography
                component="span"
                fontSize="12px"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                min
              </Typography>
            </div>
            <div>
              <Typography
                component="span"
                fontSize="10px"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                Total Operated time
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {/* Conditionally render the breakdown table */}
      <Collapse in={showBreakdown} timeout="auto" unmountOnExit>
        <Box sx={{ margin: "10px" }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Equipments</StyledTableCell>
                  <StyledTableCell align="center">Area Covered</StyledTableCell>
                  <StyledTableCell align="center">
                    Production Rate
                  </StyledTableCell>
                  <StyledTableCell align="center">Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summary?.beacons_list &&
                  Object.keys(summary.beacons_list).map((equipment) => {
                    const time = convertSecondsToHoursAndMinutes(
                      summary?.beacons_list[equipment]?.total_duration
                    );
                    return (
                      <TableRow key={equipment}>
                        <StyledTableCell>{getEquipmentName(equipment)}</StyledTableCell>
                        <StyledTableCell align="center">
                          {summary?.location_key?.includes("hard-edge")
                            ? summary?.beacons_list[
                                equipment
                              ]?.distance?.toFixed(2)
                            : summary?.beacons_list[equipment]?.area?.toFixed(
                                2
                              )}{" "}
                          {summary?.location_key?.includes("hard-edge")
                            ? areaUnit || "ft"
                            : areaUnit || "sqft"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {summary?.location_key?.includes("hard-edge")
                            ? convertProductionUnitToStoredUnit(
                                (summary?.beacons_list[equipment]?.distance *
                                  3600) /
                                  summary?.beacons_list[equipment]
                                    ?.total_duration,
                                "mph",
                                productionUnit || "ftph"
                              )
                            : convertProductionUnitToStoredUnit(
                                (summary?.beacons_list[equipment]?.area *
                                  3600) /
                                  summary?.beacons_list[equipment]
                                    ?.total_duration,
                                "sqmph",
                                productionUnit || "sqftph"
                              )}{" "}
                          {summary?.location_key?.includes("hard-edge")
                            ? unitReadableFormat[productionUnit] || "ft/hr"
                            : unitReadableFormat[productionUnit] || "sqft/hr"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {time?.hours} hr {time?.minutes} min
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>

      <div className="summaryCardFooter">
        <div>
          <Box
            className="breakDownButton"
            onClick={handleBreakdownClick}
            sx={{ background: greenShade1.main }}
          >
            {showBreakdown ? (
              <RiSubtractFill color="#326703" fontSize={16} />
            ) : (
              <RiAddBoxFill color="#326703" fontSize={16} />
            )}
            <Typography
              component="div"
              fontWeight="600"
              fontSize="12px"
              sx={{ color: "primary.dark" }}
              variant="subtitle2"
            >
              Breakdown by Equipments
            </Typography>
          </Box>
        </div>
        <div>
          <Box
            className="redirectButton"
            onClick={() => handleDetailedClick(summary)}
            sx={{ background: greenShade1.main }}
          >
            <RiArrowRightUpFill
              color="#326703"
              fontSize={16}
              fontWeight={600}
            />
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default SummaryCard;
