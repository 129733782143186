export const calculateBounds = (features) => {
  let bounds = [Infinity, Infinity, -Infinity, -Infinity];
  features.forEach((feature) => {
    const geometry = feature.geometry;
    if (geometry.type === "GeometryCollection") {
      geometry.geometries.forEach((geo) => {
        calculateBoundsForGeometry(geo, bounds);
      });
    } else {
      calculateBoundsForGeometry(geometry, bounds);
    }
  });
  return bounds;
};

export const calculateBoundsForGeometry = (geometry, bounds) => {
  const type = geometry?.type;
  const coords = geometry?.coordinates;
  switch (type) {
    case "Point":
      bounds[0] = Math.min(bounds[0], coords[0]);
      bounds[1] = Math.min(bounds[1], coords[1]);
      bounds[2] = Math.max(bounds[2], coords[0]);
      bounds[3] = Math.max(bounds[3], coords[1]);
      break;
    case "LineString":
    case "MultiPoint":
      coords.forEach((coord) => {
        bounds[0] = Math.min(bounds[0], coord[0]);
        bounds[1] = Math.min(bounds[1], coord[1]);
        bounds[2] = Math.max(bounds[2], coord[0]);
        bounds[3] = Math.max(bounds[3], coord[1]);
      });
      break;
    case "Polygon":
    case "MultiLineString":
      coords.forEach((coordArr) => {
        coordArr.forEach((coord) => {
          bounds[0] = Math.min(bounds[0], coord[0]);
          bounds[1] = Math.min(bounds[1], coord[1]);
          bounds[2] = Math.max(bounds[2], coord[0]);
          bounds[3] = Math.max(bounds[3], coord[1]);
        });
      });
      break;
    case "MultiPolygon":
      coords.forEach((polyCoords) => {
        polyCoords.forEach((coordArr) => {
          coordArr.forEach((coord) => {
            bounds[0] = Math.min(bounds[0], coord[0]);
            bounds[1] = Math.min(bounds[1], coord[1]);
            bounds[2] = Math.max(bounds[2], coord[0]);
            bounds[3] = Math.max(bounds[3], coord[1]);
          });
        });
      });
      break;
    default:
      break;
  }
};

export const calculateZoom = (bounds, width, height) => {
  const WORLD_SIZE = 512;
  const ZOOM_MAX = 20;

  const lngFraction = (bounds[2] - bounds[0]) / 360;
  const latFraction =
    (Math.log(Math.tan((bounds[3] * Math.PI) / 180 / 2 + Math.PI / 4)) -
      Math.log(Math.tan((bounds[1] * Math.PI) / 180 / 2 + Math.PI / 4))) /
    (2 * Math.PI);

  const zoom = Math.min(
    Math.log(width / (lngFraction * WORLD_SIZE)) / Math.LN2,
    Math.log(height / (latFraction * WORLD_SIZE)) / Math.LN2,
    ZOOM_MAX
  );

  return zoom-0.3;
};
