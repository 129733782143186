import React, { useEffect, useState, useRef } from "react";
import "./MapScreen.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setResetAnimation,
  setSelectedCrewMembersPathId,
} from "../../slices/globalSlice";
import {
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import CrewMemberDaySelect from "./CrewMemberDaySelect";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";
import { TbTimeline } from "react-icons/tb";
import { AiOutlineControl } from "react-icons/ai";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import PlannerDetailsDrawer from "./PlannerDetailsDrawer";
import MapControlsDrawer from "./MapControlsDrawer";
import { RxHamburgerMenu } from "react-icons/rx";
import { capitalizeFirstLetter, truncateString } from "../../utils/common";
import SelectPlanModal from "../../Modals/SelectPlanModal/SelectPlanModal";
import TimelineDrawer from "./TimelineDrawer";
import useViewportWidth from "../../Hooks/useViewportWidth";
import { APIProvider } from "@vis.gl/react-google-maps";
import PlannerGoogleMaps from "../PlannerGoogleMap/PlannerGoogleMaps";

const MapScreen = ({ initialCrewMembers }) => {
  const mapRef = useRef(null);
  const [drawerHeight, setDrawerHeight] = useState(0);
  const drawerRef = useRef();
  const width = useViewportWidth();
  const dispatch = useDispatch();
  const mapData = useSelector((state) => state.global.mapData);
  const crewMemberDayWiseFilteredData = useSelector(
    (state) => state.global.crewMemberDayWiseFilteredData
  );
  const isStreetView = useSelector((state) => state.global.isStreetView);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const resetAnimation = useSelector((state) => state.global.resetAnimation);
  const selectedCrewMembersId = useSelector(
    (state) => state.global.selectedCrewMembersId
  );
  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
  const [isTimelineDrawerOpen, setIsTimelineDrawerOpen] = useState(false);
  const [isAnimationDrawerOpen, setIsAnimationDrawerOpen] = useState(false);
  const [isPlannerDrawerOpen, setIsPlannerDrawerOpen] = useState(true);
  const [animationState, setAnimationState] = useState("stopped");
  const [animatedLines, setAnimatedLines] = useState([]);
  const [crewLines, setCrewLines] = useState([]);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [completedLines, setCompletedLines] = useState([]);
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [hoveredPolygon, setHoveredPolygon] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const animateFile = useSelector((state) => state.global.animationData);
  const intervalRef = useRef(null);
  const stepRef = useRef(0);
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    // Ensure the drawerRef is currently pointing to an element
    const drawerElement = drawerRef.current;
    if (!drawerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDrawerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(drawerElement);

    // Cleanup function to safely unobserve
    return () => {
      if (drawerElement) {
        resizeObserver.unobserve(drawerElement);
      }
    };
  }, [isTimelineDrawerOpen]);

  useEffect(() => {
    if (resetAnimation) {
      handleClearAnimation();
      setAnimationSpeed(1);
    }
  }, [resetAnimation]);

  // for animation

  useEffect(() => {
    if (animateFile) {
      const crewLinesData = Object.keys(animateFile).map((crewId) => {
        return {
          type: "Feature",
          properties: {
            crewId,
            crewMemberColor: animateFile[crewId].crewMemberColor,
            isDotted: true,
          },
          geometry: {
            type: "LineString",
            coordinates: animateFile[crewId]?.day[0]?.data
              .filter((data) => !data.dotted) // Keep only the points where work is done
              .map((data) => [data.point[0], data.point[1]]), // Ignore altitude for the line
          },
        };
      });
      setCrewLines(crewLinesData);
    }
  }, [animateFile]);

  useEffect(() => {
    initializeAnimation();
    // This effect should run once on mount
  }, [crewLines]); // Dependencies array is empty, so this runs once on mount

  const initializeAnimation = () => {
    setAnimatedLines(
      crewLines.map((line) => ({
        ...line,
        geometry: { type: "LineString", coordinates: [] },
      }))
    );
    setMarkerPositions(crewLines.map(() => null)); // Initialize markers as null
    stepRef.current = 0;
  };

  const startAnimation = () => {
    if (animationState !== "playing") {
      dispatch(setResetAnimation(false));
      setAnimationState("playing");
      // Directly proceed with the first step to ensure immediate visibility
      animateStep();
    }
  };

  const animateStep = () => {
    const maxSteps = Math.max(
      ...Object.values(animateFile).map(
        (dayData) => dayData?.day[0]?.data?.length
      )
    );

    if (stepRef.current < maxSteps) {
      setAnimatedLines((animatedLines) =>
        animatedLines.map((animatedLine, index) => {
          const originalData =
            animateFile[Object.keys(animateFile)[index]]?.day[0]?.data;
          const nextStep =
            stepRef?.current < originalData?.length
              ? stepRef?.current
              : originalData?.length - 1;
          const currentPoint = originalData[nextStep];
          const shouldKeep = currentPoint ? currentPoint?.keep : false;

          // Update marker positions for all points
          if (currentPoint) {
            const newMarkerPosition = {
              longitude: currentPoint?.point[0],
              latitude: currentPoint?.point[1],
            };

            setMarkerPositions((markerPositions) => {
              const newMarkerPositions = [...markerPositions];
              newMarkerPositions[index] = newMarkerPosition;
              return newMarkerPositions;
            });
          }

          if (shouldKeep) {
            const newLineSegment = {
              ...animatedLine,
              properties: { ...animatedLine.properties }, // Preserve properties
              geometry: {
                ...animatedLine.geometry,
                coordinates: [
                  ...animatedLine.geometry.coordinates,
                  [currentPoint.point[0], currentPoint.point[1]],
                ],
              },
            };

            // If transitioning from a transit segment to a work segment
            if (
              animatedLine.geometry.coordinates.length === 0 ||
              !originalData[nextStep - 1]?.keep
            ) {
              // Add the last completed line to completedLines
              if (animatedLine.geometry.coordinates.length > 0) {
                setCompletedLines((prevLines) => [...prevLines, animatedLine]);
              }
              return newLineSegment; // Start a new line segment
            } else {
              return newLineSegment; // Continue extending the current line segment
            }
          } else {
            // At transit points, ensure the current working line is completed
            if (animatedLine.geometry.coordinates.length > 0) {
              setCompletedLines((prevLines) => [...prevLines, animatedLine]);
              // Reset the current line to empty for the next work segment
              return {
                ...animatedLine,
                properties: { ...animatedLine.properties }, // Preserve properties
                geometry: {
                  ...animatedLine.geometry,
                  coordinates: [],
                },
              };
            }
            return animatedLine; // No change to the line during transit segments
          }
        })
      );
      stepRef.current += animationSpeed;

      if (stepRef.current < maxSteps) {
        intervalRef.current = setTimeout(animateStep, 100 / animationSpeed);
      } else {
        setAnimationState("stopped");
      }
    }
  };

  const stopAnimation = () => {
    clearTimeout(intervalRef.current);
    setAnimationState("stopped");
  };

  const restartAnimation = () => {
    toast.success("Animation Restarted", {
      autoClose: 1000,
      hideProgressBar: true,
    });
    stopAnimation();
    setAnimatedLines([]);
    setCompletedLines([]);
    initializeAnimation();
    // Start the animation after a brief timeout to ensure the state is reset
    setTimeout(startAnimation, 100);
  };

  const handleClearAnimation = () => {
    stopAnimation();
    setAnimatedLines([]);
    setCompletedLines([]);
    initializeAnimation();
  };

  useEffect(() => {
    if (selectedCrewMembersId) {
      const crewMemberPolygons = {}; // Object to hold crewMemberId: [polygons]

      Object.keys(lookUpData.crewMemberDayWise).forEach((crewMemberId) => {
        const isSelected = selectedCrewMembersId[crewMemberId];

        if (isSelected) {
          const crewMember = lookUpData.crewMemberDayWise[crewMemberId];
          crewMemberPolygons[crewMemberId] = [];

          crewMember?.day.forEach((day) => {
            if (day?.day === selectedDay) {
              // Check if the day matches the selected day
              crewMemberPolygons[crewMemberId] = day?.polygon;
            } else if (selectedDay == "All") {
              crewMemberPolygons[crewMemberId] = [
                ...crewMemberPolygons[crewMemberId],
                ...day?.polygon,
              ];
            }
          });

          // case: unAssigned Polygons
          if (crewMember?.crewId === 0) {
            crewMember?.day?.forEach((day) => {
              crewMemberPolygons[crewMemberId] = day?.polygon;
            });
          }
        }
      });
      dispatch(setSelectedCrewMembersPathId(crewMemberPolygons));
    }
  }, [selectedCrewMembersId]);
  const handleAnimationSpeed = (e) => {
    setAnimationSpeed(e?.target?.value);
  };

  const toggleMapControlsDrawer = () => {
    setIsAnimationDrawerOpen(!isAnimationDrawerOpen);
  };
  const togglePlannerDrawer = () => {
    setIsPlannerDrawerOpen(!isPlannerDrawerOpen);
  };

  const handleMapControlsDrawer = () => {
    setIsAnimationDrawerOpen(!isAnimationDrawerOpen);
  };
  const handleViewTimelineButton = () => {
    setIsTimelineDrawerOpen(!isTimelineDrawerOpen);
  };

  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        {/* <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}> */}
        <APIProvider apiKey="AIzaSyDVIMgTN3Ul0B78ZnXbj_tb37j8BjzzrFY">
          <PlannerGoogleMaps
            hoveredPolygon={hoveredPolygon}
            mapData={mapData}
            crewMemberDayWise={crewMemberDayWiseFilteredData}
            polygonsData={lookUpData?.polygons}
            initialCrewData={initialCrewMembers}
            animatedLines={animatedLines}
            completedLines={completedLines}
            markerPositions={markerPositions}
            mapRef={mapRef}
            drawerHeight={drawerHeight}
            isTimelineDrawerOpen={isTimelineDrawerOpen}
          />
        </APIProvider>
      </div>
      {!isPlannerDrawerOpen && !isStreetView && (
        <div className="plannerDetailsOpener">
          <RxHamburgerMenu
            size={22}
            cursor="pointer"
            className="hamburgerMenu"
            onClick={togglePlannerDrawer}
          />{" "}
          <div className="siteNameOnTop">
            <Tooltip title={lookUpData?.summary?.site} arrow>
              <Typography fontWeight={600} fontSize={14}>
                {capitalizeFirstLetter(lookUpData?.summary?.site)
                  ? truncateString(lookUpData?.summary?.site?.toUpperCase(), 23)
                  : "Sitename not found"}
              </Typography>
            </Tooltip>
          </div>
        </div>
      )}
      {!isStreetView && (
        <>
          <button
            className="viewTimelineButton"
            onClick={handleViewTimelineButton}
          >
            <TbTimeline size={20} /> <Typography>View Timeline </Typography>{" "}
            <TiArrowSortedUp />
          </button>
          <button className="viewMapControls" onClick={handleMapControlsDrawer}>
            <AiOutlineControl size={20} /> <Typography>Map Controls</Typography>{" "}
            <TiArrowSortedDown />
          </button>
        </>
      )}

      {isTimelineDrawerOpen && (
        <div
          className={`changeTimelineSection ${
            isTimelineDrawerOpen ? "active" : ""
          }`}
          style={{
            bottom: `${drawerHeight}px`, // Adjust based on drawer's height
            left: isPlannerDrawerOpen && width > 768 ? "27vw" : 0,
          }}
        >
          {width > 500 ? (
            <div className="tabChange">
              <Tabs
                value={tabValue}
                fontSize={10}
                onChange={handleTabChange}
                centered
              >
                <Tab fontSize={10} label="Crew Member" />
                <Tab label="Crew Wise" />
                <Tab label="Service Wise" />
              </Tabs>
            </div>
          ) : (
            <Select
              value={tabValue}
              className="tabSelect"
              onChange={(e) => setTabValue(e.target.value)}
              fullWidth
            >
              <MenuItem selected value={0}>
                Crew Member
              </MenuItem>
              <MenuItem value={1}>Crew Wise</MenuItem>
              <MenuItem value={2}>Service Wise</MenuItem>
            </Select>
          )}
            <CrewMemberDaySelect data={lookUpData?.crewMemberDayWise} />
        </div>
      )}
      <PlannerDetailsDrawer
        togglePlannerDrawer={togglePlannerDrawer}
        isPlannerDrawerOpen={isPlannerDrawerOpen}
      />
      <MapControlsDrawer
        isAnimationDrawerOpen={isAnimationDrawerOpen}
        startAnimation={startAnimation}
        stopAnimation={stopAnimation}
        restartAnimation={restartAnimation}
        completedLines={completedLines}
        handleClearAnimation={handleClearAnimation}
        animationSpeed={animationSpeed}
        handleAnimationSpeed={handleAnimationSpeed}
        animationState={animationState}
        toggleMapControlsDrawer={toggleMapControlsDrawer}
      />
      {isTimelineDrawerOpen && (
        <div>
          <button
            className={`closeTimelineButton`}
            style={{
              bottom: `${drawerHeight}px`, // Adjust based on drawer's height
            }}
            onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
          >
            <IoMdArrowDropdown />
          </button>
        </div>
      )}
      <TimelineDrawer
        isPlannerDrawerOpen={isPlannerDrawerOpen}
        isTimelineDrawerOpen={isTimelineDrawerOpen}
        setHoveredPolygon={setHoveredPolygon}
        drawerRef={drawerRef}
        tabValue={tabValue}
      />

      <SelectPlanModal
        isOpen={isSelectPlanOpen}
        setIsOpen={setIsSelectPlanOpen}
      />
    </div>
  );
};

export default MapScreen;
