import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ParkingInfo from "./ParkingInfo";
import ParcelWorker from "./ParcelWorker";
import ParcelService from "./ParcelService";
import CompanyInfo from "./CompanyInfo";
import {
  useLazyGetSummaryQuery,
  useOrderStatusMutation,
} from "../../services/OpenApi";
import { useNavigate, useParams } from "react-router-dom";
import SurveyMap from "../GoogleMapsDraw/SurveyMap";
import { APIProvider } from "@vis.gl/react-google-maps";
import SummaryMap from "./SummaryMap";
import { getCustomColor } from "../../utils/theme";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import "./SummaryScreen.css";
import { RotateLoader } from "react-spinners";

const SummaryScreen = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const [orderStatus, { isLoading: isOrderStatusLoading }] =
    useOrderStatusMutation();
  const params = useParams();
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const [getSummary, { isLoading }] = useLazyGetSummaryQuery();

  useEffect(() => {
    (async () => {
      try {
        const response = await getSummary({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        if (response) {
          setSummaryData(response?.data);
        }
      } catch (error) {
        // toast.error(error?.data?.detail, {
        //   autoClose: 2000,
        //   hideProgressBar: true,
        // });
      }
    })();
  }, []);

  const handleStartMeasuring = async () => {
    try {
      const response = await orderStatus({
        orderId: params?.id,
        orgName: user?.organization,
        status: "SUBMITTED",
      });
      if (response?.data) {
        navigate("/");
        toast.success("Order recieved successfully", {
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading)
    return (
      <div className="spinner">
        <RotateLoader color="#8FE445" size={20} margin={5} />
      </div>
    );

  return (
    summaryData && (
      <Container className="summaryContainer" marginTop={5}>
        <Grid marginTop={8} container spacing={3}>
          <Grid item xs={6}>
            <CompanyInfo
              companyName={summaryData?.companyName}
              address={summaryData?.address}
              siteName={summaryData?.siteName}
              orderStatus={summaryData?.orderStatus}
              description={summaryData?.description}
            />
          </Grid>
          <Grid item xs={6} height={300}>
            <APIProvider apiKey="AIzaSyDVIMgTN3Ul0B78ZnXbj_tb37j8BjzzrFY">
              <SummaryMap
                parcelData={summaryData?.parcel}
                parkingData={summaryData?.parking}
              />
            </APIProvider>
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              sx={{ color: blackShade1.main }}
              textAlign="center"
              variant="h4"
              component="div"
            >
              Parkings
            </Typography>
            <ParkingInfo parkings={summaryData?.parking} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              sx={{ color: blackShade1.main }}
              textAlign="center"
              variant="h4"
              component="div"
            >
              Services
            </Typography>
            <ParcelService parcelService={summaryData?.parcelService} />
          </Grid>
          <Grid item xs={12}>
            <ParcelWorker parcelWorker={summaryData?.parcelWorker} />
          </Grid>
        </Grid>
        {!(summaryData?.orderStatus === "SUBMITTED") && (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              type="submit"
              fullWidth
              disabled={isOrderStatusLoading}
              onClick={() => setIsConfirmationModalOpen(true)}
              variant="contained"
              sx={{ mt: 3, mb: 2, boxShadow: "none", width: "200px" }}
            >
              {isOrderStatusLoading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        )}
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          body="Please review your details carefully. Once you click 'Continue', you will not be able to edit your information."
          secondaryButtonTitle="Edit Details"
          PrimaryButtonTitle="Confirm"
          secondaryButtonVariant="outlined"
          primaryButtonVariant="outlined"
          secondaryButtonColor="primary"
          secondaryButtonClick={() => navigate(`${`/parcel/${params?.id}`}`)}
          handleOk={handleStartMeasuring}
        />
      </Container>
    )
  );
};

export default SummaryScreen;
