import "./App.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "./Routes/AppRoutes";
import {
  setDefaultUnits,
  setIsAllProductsPermission,
  setIsLogin,
  setToken,
  setUser,
} from "./slices/globalSlice";
import { useTokenRefresh } from "./utils/refreshTokenUtils";
import { useLazyGetOrganizationDetailsQuery } from "./services/Auth";

function App() {
  const isToken = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = useSelector((state) => state.global.token);
  const [getOrganizationDetails] = useLazyGetOrganizationDetailsQuery();
  const dispatch = useDispatch();
  useTokenRefresh(token);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, []);

  const fetchAndSetOrganizationDetails = async (organization) => {
    try {
      const data = await getOrganizationDetails(organization).unwrap();
      dispatch(setDefaultUnits(data?.meta_data?.defaultUnits));

      localStorage.setItem("metaData", JSON.stringify(data?.meta_data));
      if (data?.meta_data?.application_services) {
        const { crew_intel, crew_planner } =
          data?.meta_data?.application_services;

        if (crew_intel && crew_planner) {
          dispatch(setIsAllProductsPermission(true));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (user?.organization) {
      fetchAndSetOrganizationDetails(user?.organization);
    }
  }, []);

  if (isToken && !token) {
    dispatch(setToken(isToken));
    dispatch(setIsLogin(true));
  }
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
