import {
  ControlPosition,
  Map,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";
import { calculateBounds } from "../../utils/mapZoomCalculation";
import BaseServiceLayers from "../GISDashboard/Layers/BaseServiceLayers";

const UploadKmlGoogleMap = () => {
  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const uploadedKmlData = useSelector(
    (state) => state.gisDashboard.uploadedKmlData
  );
  const [layers, setLayers] = useState([]);
  const baseLayersGeojson = uploadedKmlData?.geometry;
  useEffect(()=>{
    if(uploadedKmlData && uploadedKmlData.geometry){
      let layersData = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: uploadedKmlData.geometry
          }
        ]
      }
      setLayers(layersData)
    }
  },[uploadedKmlData])
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  useEffect(() => {
    if (
      !layers ||
      !layers.features ||
      layers.features.length === 0
    ) {
      return;
    }

    const bounds = calculateBounds(layers.features);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },

      zoom: 18,
    }));
  }, [layers]);

  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        // disableDefaultUI= {true}
        mapId={"84a4fccd359c6c70"}
        // mapId={"e29610dc72ad5f45"}
        // mapId={"24ece105f1731113"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
        stylers={true}
      >
        <BaseServiceLayers
          layerData={layers}
          // coveredFilteredGeoJson={coveredFilteredGeoJson}
        />
      </Map>
    </div>
  );
};

export default UploadKmlGoogleMap;
