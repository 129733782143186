import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import {
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
} from "../../utils/common";
import { useSelector } from "react-redux";

const CrewMemberWiseTimeline = () => {
  const timelineRef = useRef();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [itemsSummaryData, setItemsSummaryData] = useState([]);
  const [summary, setSummary] = useState(false);
  const [options, setOptions] = useState({
    stack: false,
    height: "35vh",
    zoomMin: 1000, // Adjust as necessary
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };

  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const transformDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const serviceName = getServiceName(polygon?.serviceId).replace(
            /\s/g,
            "_"
          );
          const equipmentName = getEquipmentName(polygon.crewEquipmentId);

          const task = {
            id: polygonId,
            content: `${polygonId}`,
            start: convertTimestampToFormattedDate(polygon.arrivalTime),
            end: convertTimestampToFormattedDate(polygon.departureTime),
            group: `${crewMemberName} ${polygon?.serviceName} ${polygon?.serviceId}`,
            title: `${crewMemberName} ${polygon?.serviceName} ${polygon?.serviceId}`,
            title: `<div>
          <div class="individualContainer">
          <div>Crew Member : ${crewMemberName}</div>
          <div>Service Name : ${serviceName.replace("_", " ")}</div>
          <div>Equipment Name : ${equipmentName.replace("_", " ")}</div>
          <div>Start Time : ${convertTimestampToFormattedDate(
            polygon.arrivalTime
          )}</div>
          <div>End Time : ${convertTimestampToFormattedDate(
            polygon.departureTime
          )}</div>
          </div>
          </div>`,
            className: `${serviceName}`, // You can define custom CSS classes here
          };

          timelineData.push(task);
        });
      });
    }

    return timelineData;
  };
  const transformDataForTimelineSummary = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;
        let currentServiceName = null;
        let currentServiceId = null;
        let currentStartTime = null;
        let currentEndTime = null;
        let currentCrewMemberName = null;
        let currentCrewMemberId = null;
        let currentPolygonId = null;

        polygons.forEach((polygonId, index) => {
          const polygon = lookup.polygons[polygonId];

          if (currentServiceName === null) {
            // This is the first polygon

            currentServiceName = getServiceName(polygon.serviceId);
            currentServiceId = polygon.serviceId;
            currentStartTime = polygon.arrivalTime;
            currentCrewMemberId = polygon.crewMemberId;
            currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
            currentPolygonId = polygonId;
          } else if (currentServiceName === getServiceName(polygon.serviceId)) {
            // Same service, update the end time
            currentEndTime = polygon.departureTime;
          } else {
            // Service name changed, push the previous task and start a new one
            pushTask(
              currentPolygonId,
              currentServiceName,
              currentServiceId,
              currentStartTime,
              currentEndTime,
              currentCrewMemberName,
              currentCrewMemberId
            );

            // Check for time gap and add a task for the gap
            if (index >= 1) {
              const previousPolygonId = polygons[index - 1];
              const previousPolygon = lookup.polygons[previousPolygonId];
              const serviceName = getServiceName(previousPolygon.serviceId);
              const gapStartTime = previousPolygon.departureTime;
              const gapEndTime = polygon.arrivalTime;

              pushTask(
                previousPolygonId,
                serviceName,
                previousPolygon.serviceId,
                gapStartTime,
                gapEndTime,
                currentCrewMemberName,
                currentCrewMemberId,
                "Gap"
              );
            }

            currentServiceName = getServiceName(polygon.serviceId);
            currentStartTime = polygon.arrivalTime;
            currentServiceId = polygon.serviceId;
            currentCrewMemberId = polygon.crewMemberId;
            currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
            currentPolygonId = polygonId;
          }

          // Always update the end time to the current polygon's departure time
          currentEndTime = polygon.departureTime;

          // If it's the last polygon, push the task
          if (index === polygons.length - 1) {
            pushTask(
              currentPolygonId,
              currentServiceName,
              currentServiceId,
              currentStartTime,
              currentEndTime,
              currentCrewMemberName,
              currentCrewMemberId
            );
          }
        });
      });

      function pushTask(
        polygonId,
        serviceName,
        serviceId,
        startTime,
        endTime,
        crewMemberName,
        crewMemberId,
        gap
      ) {
        const task = {
          id:
            gap === "Gap"
              ? `Back To Truck ${polygonId} ${crewMemberName}`
              : `${polygonId} ${crewMemberName}`,
          content:
            gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " "), // Change content for Gap
          start: convertTimestampToFormattedDate(startTime),
          end: convertTimestampToFormattedDate(endTime),
          group: `${crewMemberId}`,
          title: `<div>
          <div class="individualContainer">
          <div>Crew Member : ${crewMemberName}</div>
          <div>Service Name : ${
            gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " ")
          }</div>
          <div>Start Time : ${convertTimestampToFormattedDate(startTime)}</div>
          <div>End Time : ${convertTimestampToFormattedDate(endTime)}</div>
          </div>
          </div>`,
          className:
            gap === "Gap" ? "Back_To_Truck" : serviceName.replace(" ", "_"), // Custom CSS class
        };

        timelineData.push(task);
      }
    }

    return timelineData;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const crewMembers = lookup.crewMembers;
    const crews = lookup.crews;
    const groupData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const serviceName = getServiceName(polygon.serviceId).replace(
            /_/g,
            " "
          );
          const uniqueId = `${crewMemberName} ${polygon?.serviceName} ${polygon?.serviceId}`;

          const group = {
            id: uniqueId,
            content: `${serviceName}`,
          };
          const groupExists = groupData.some(
            (existingGroup) => existingGroup.id === uniqueId
          );

          if (!groupExists) {
            groupData.push(group);
          }
        });
      });
    }

    for (const crewMemberId in crewMembers) {
      const crewMemberData = crewMembers[crewMemberId];
      const nestedGroups = [];

      const group = {
        id: crewMemberId,
        content: `${crewMemberData?.name}`,
        showNested: false,
        value: parseInt(crewMemberId),
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      groupData.forEach((item) => {
        if (item.id.includes(crewMemberData?.name)) {
          nestedGroups.push(item.id);
        }
      });

      const containsName = groupData.some((item) =>
        item.id.includes(crewMemberData?.name)
      );

      if (containsName) {
        group.nestedGroups = nestedGroups;
      }
      groupData.push(group);
    }
    for (const crewId in crews) {
      const crewData = crews[crewId];
      const nestedGroups = [];

      const group = {
        id: `${crewId}-${crewData?.name}`,
        content: `${crewData?.name}`,
        showNested: true,
        value: parseInt(crewId),
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      for (const crewMemberId in crewMembers) {
        const crewMemberData = crewMembers[crewMemberId];
        if (crewMemberData.crewId == crewId) {
          nestedGroups.push(crewMemberId);
        }
      }

      group.nestedGroups = nestedGroups;

      groupData.push(group);
    }

    return groupData;
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberDayWise = lookup.crewMemberDayWise;

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const startDate = convertTimestampToFormattedDate(
            polygon.arrivalTime
          );
          const endDate = convertTimestampToFormattedDate(
            polygon.departureTime
          );
          if (!minStartTime || startDate < minStartTime) {
            minStartTime = startDate;
          }
          if (!maxEndTime || endDate > maxEndTime) {
            maxEndTime = endDate;
          }
        });
      });
    }

    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (lookUp) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(lookupData);
      setItemsData(timelineData);
      const timelineDataSummary = transformDataForTimelineSummary(lookupData);
      setItemsSummaryData(timelineDataSummary);
      setItemsData((prevData) => [...prevData, ...timelineDataSummary]);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [lookUp, selectedDay]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={summary ? itemsSummaryData : itemsData}
        />
      )}
    </div>
  );
};

export default CrewMemberWiseTimeline;
