import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { config } from "./config";
import { APIENDPOINTS } from "./constants";
import {
  reset as resetGlobal,
  setIsLogin,
  setToken,
} from "../slices/globalSlice";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reset as resetWorkPlan } from "../slices/workPlanSlice";

// Existing base queries
const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().global.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }
    return headers;
  },
});

const openBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL_OPEN,
});

const testingBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL_TESTING,
});

// Base query with reauthorization logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await rtkBaseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const state = api.getState();
    const token = state.global.token;
    try{

      const {data} = await rtkBaseQuery(
        {
          url: APIENDPOINTS.REFRESHTOKEN,
          method: 'POST',
          body: { token: token },
        },
        api,
        extraOptions
      );
      
      if (data.token) {
        api.dispatch(setToken(data?.token));
        localStorage.setItem("accessToken", data.token);
      } else {
        api.dispatch(resetGlobal());
        api.dispatch(resetWorkPlan());
        api.dispatch(setIsLogin(false));
        localStorage.clear();
        // toast.error("Session Timeout", {
        //   autoClose: 1000,
        //   hideProgressBar: true,
        // });
      }
    }catch(err) {
      api.dispatch(resetGlobal());
      api.dispatch(resetWorkPlan());
      api.dispatch(setIsLogin(false));
      localStorage.clear();
      // toast.error("Session Timeout", {
      //   autoClose: 1000,
      //   hideProgressBar: true,
      // });
    }
  }

  return result;
};

// Export your enhanced base query
export { baseQueryWithReauth, openBaseQuery, testingBaseQuery };
