import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  setDefaultUnits,
  setIsLogin,
  setToken,
  setUser,
} from "../../slices/globalSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useLazyGetOrganizationDetailsQuery,
  useLoginMutation,
} from "../../services/Auth";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useDispatch } from "react-redux";
import { setIsLocationSet } from "../../slices/workPlanSlice";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import "./Login.css";
import { getCustomColor } from "../../utils/theme";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import AuthDesignWrapper from "../../components/AuthDesignWrapper/AuthDesignWrapper";
import { checkIfLogin } from "../../utils/common";
import {
  useCreateOrganisationForSchemaMutation,
  useLazyGetOrganisationForSchemaQuery,
} from "../../services/OpenApi";

export default function Login() {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm();
  const blackShade1 = getCustomColor("blackShade1");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [getOrganizationDetails] = useLazyGetOrganizationDetailsQuery();
  const [getOrganisationForSchema] = useLazyGetOrganisationForSchemaQuery();
  const [createOrganisationForSchema] =
    useCreateOrganisationForSchemaMutation();
  const onSubmit = async (data) => {
    const values = {
      username: data.email,
      password: data.password,
    };

    const existingUser = localStorage.getItem("user");
    if (existingUser) {
      toast.warning("A user is already logged in. Please log out first.");
      return;
    }

    try {
      const response = await login(values).unwrap();
      if (!response) return;

      const { user, token } = response;
      if (!user?.organization) {
        dispatch(setToken(token));
        dispatch(setIsLogin(true));
        console.log("user -->> ",user)
        dispatch(setUser(user));
        localStorage.setItem("accessToken", token);
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/newuser", { replace: true });
        return;
    }

      await handleOrganizationSchema(user, token);
      dispatch(setToken(token));
      dispatch(setIsLogin(true));
      console.log("user -->> ",user, user?.organization)
      dispatch(setUser(user));
      localStorage.setItem("accessToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      await fetchAndSetOrganizationDetails(
        user.organization
      );
      finalizeLogin(user, token);
      navigate("/", { replace: true });
    } catch (error) {
      handleLoginError(error);
    }
  };

  const fetchAndSetOrganizationDetails = async (organization) => {
    try {
      const data = await getOrganizationDetails(organization).unwrap();
      dispatch(setDefaultUnits(data?.meta_data?.defaultUnits));
      localStorage.setItem("metaData", JSON.stringify(data?.meta_data));
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while fetching organization details", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      throw error;
    }
  };

  const handleOrganizationSchema = async (user, token) => {
    try {
      const orgDetails = await getOrganisationForSchema({
        organisationName: user.organization,
        userEmail: user.email,
      }).unwrap();

      if (!orgDetails?.data?.organisation && !orgDetails?.data?.user) {
        await createOrganisationForSchema({
          organisationName: user.organization,
          userEmail: user.email,
          linkWithOrganisation: false,
        });
      } else if (orgDetails?.data?.organisation && !orgDetails?.data?.user) {
        await createOrganisationForSchema({
          organisationName: user.organization,
          userEmail: user.email,
          linkWithOrganisation: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while handling organization schema", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      throw error;
    }
  };

  const finalizeLogin = (user, token) => {
    toast.success("Logged in successfully", {
      autoClose: 1000,
      hideProgressBar: true,
    });
    dispatch(setIsLocationSet(false));
  };

  const handleLoginError = (error) => {
    console.error(error);
    toast.error(error?.data?.detail || "Something went wrong during login", {
      autoClose: 1000,
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    if (checkIfLogin()) {
      navigate("/", { replace: true }); // Use replace to modify the history stack
    }
  }, []);

  return (
    <AuthDesignWrapper
      body={
        <>
          <Typography
            component="h3"
            fontWeight="600"
            sx={{ color: blackShade1.main }}
            variant="h5"
          >
            Login
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              size="small"
              id="email"
              label="Email"
              name="email"
              autoFocus
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Enter a valid email address",
                },
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              size="small"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="password"
              autoComplete="password"
              {...register("password", {
                required: "Password is required",
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    "Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character",
                },
              })}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{ mt: 3, mb: 2, boxShadow: "none" }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Login"}
            </Button>
            <SecondaryButton
              type="button"
              fullWidth
              marginBottom="20px"
              variant="contained"
              background="#E0FFC4"
              textcolor="#3F8203"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password
            </SecondaryButton>
            <div className="createOne">
              <Typography component="h3" variant="body2">
                Don't have an account?{" "}
                <Typography
                  onClick={() => navigate("/register")}
                  sx={{ cursor: "pointer" }}
                  component="span"
                  color="primary.dark"
                  variant="body2"
                >
                  Create one
                </Typography>
              </Typography>
            </div>
          </Box>
        </>
      }
    />
  );
}
