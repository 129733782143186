import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SummaryCard from "./SummaryCard";
import {
  getServiceColorFromLocationKey,
  getWeekOfYear,
  getWeekRange,
} from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { getCustomColor } from "../../utils/theme";
import useViewportWidth from "../../Hooks/useViewportWidth";
import {
  useLazyGetPropertyBaseLayersQuery,
} from "../../services/Properties";
import {
  setBaseLayersGeojson,
  setCoveredLayersGeojson,
  setSelectedGISService,
  setSelectedGISServicePathOfMotion,
  setSelectedLocationValue,
  setSummaryData,
} from "../../slices/gisDashboardSlice";
import { FaCalendar } from "react-icons/fa";
import WeekCalender from "../../components/WeekCalender/WeekCalender";
import { RotateLoader } from "react-spinners";

const SidebarSummary = () => {
  const greenShade1 = getCustomColor("greenShade1");
  const [anchorEl, setAnchorEl] = useState(null);
  const weekCalenderValue = useSelector(
    (state) => state.gisDashboard.weekCalenderValue
  );
  const summaryData = useSelector((state) => state.gisDashboard.summaryData);

  const open = Boolean(anchorEl);
  const selectedLocationValue = useSelector(
    (state) => state.gisDashboard.selectedLocationValue
  );
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [getPropertyBaseLayers, { isLoading }] =
    useLazyGetPropertyBaseLayersQuery();
  const fetchSummaryData = async () => {
    const response = await getPropertyBaseLayers({
      orgName: user?.organization,
      location: `${selectedLocationValue?.key?.toUpperCase()}-${getWeekOfYear(
        weekCalenderValue
      )}`,
    });
    dispatch(setSummaryData(response?.data?.results));
  };

  useEffect(() => {
    if (selectedLocationValue && weekCalenderValue) {
      fetchSummaryData();
    }
  }, [selectedLocationValue, weekCalenderValue]);

  const handleLocationSelectChange = (newValue) => {
    dispatch(setSelectedLocationValue(newValue));
  };

  const handleCalendarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (summaryData) {
      const uniqueServices = {};
      const uniqueServicesForPathOfMotion = {};
      summaryData.forEach((summary) => {
        if (!uniqueServices[summary.location_key]) {
          uniqueServices[summary.location_key] = true;
          uniqueServicesForPathOfMotion[summary.location_key] = false;
        }
      });
      dispatch(setSelectedGISService(uniqueServices));
      dispatch(
        setSelectedGISServicePathOfMotion(uniqueServicesForPathOfMotion)
      );
    }
  }, [summaryData]);

  useEffect(() => {
    if (summaryData) {
      let baseGeojson = {
        type: "FeatureCollection",
        features: [],
      };
      let coveredGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      summaryData?.map((summary) => {
        let featureObj = {
          type: "Feature",
          properties: {
            location_key: summary.location_key,
            serviceName: getServiceColorFromLocationKey(summary.location_key),
          },
          geometry: summary?.location_geom,
        };
        return baseGeojson.features.push(featureObj);
      });

      summaryData?.map((summary) => {
        let featureObj = {
          type: "Feature",
          properties: {
            location_key: summary.location_key,
            serviceName: getServiceColorFromLocationKey(summary.location_key),
          },
          geometry: summary?.location_key?.includes("hard-edge")
            ? summary?.cumulative_distance_covered_geom
            : summary?.cumulative_area_covered_geom,
        };
        return coveredGeoJson.features.push(featureObj);
      });
      dispatch(setBaseLayersGeojson(baseGeojson));
      dispatch(setCoveredLayersGeojson(coveredGeoJson));
    }
  }, [summaryData]);

  return (
    <>
      <Box className="sidebarSelectContainer">
        <Select
          value={selectedLocationValue || null}
          onChange={(e) => handleLocationSelectChange(e?.target?.value)}
          size="small"
          sx={{
            background: greenShade1.main,
            color: (theme) => theme.palette.primary.dark,
            fontWeight: 600,
            fontSize: "12px",
            height: "35px",
            width: "150px",
            border: "none",
            outline: "none",
            borderWidth: 0,
          }}
        >
          {locationGroupData &&
            locationGroupData.map((location) => (
              <MenuItem key={location} value={location}>
                {location.name}
              </MenuItem>
            ))}
        </Select>
        <Box
          className="calenderButton"
          onClick={handleCalendarClick}
          sx={{ background: greenShade1.main }}
        >
          <Typography
            component="div"
            fontWeight="600"
            fontSize="12px"
            sx={{ color: "primary.dark" }}
            variant="subtitle2"
          >
            {getWeekRange(weekCalenderValue)}
          </Typography>
          {<FaCalendar color="#326703" fontSize={16} />}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCalendarClose}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <WeekCalender fetchSummaryData={fetchSummaryData} />
        </Menu>
      </Box>
      <Divider />
      <Box className="summaryContainer">
        {isLoading ? (
          <div className="spinner">
            <RotateLoader color="#8FE445" size={20} margin={5} />
          </div>
        ) : summaryData?.length > 0 ? (
          summaryData?.map((summary) => (
            <SummaryCard key={summary?.key} summary={summary} />
          ))
        ) : (
          <div className="noDataForCrewIntel">
            <Typography variant="body1">No data available</Typography>
          </div>
        )}
      </Box>
    </>
  );
};

export default SidebarSummary;
